import { createI18n } from 'vue-i18n'
import enLocale from './en';
import arLocale from './ar';

const messages = {
    en: enLocale,
    ar: arLocale,
}

const localLang = navigator.language.split('-')[0];
const storageLang = window.localStorage.getItem('locale') || 'en';
const c = (storageLang.toLocaleLowerCase() !== 'ar' && storageLang.toLocaleLowerCase() !== 'en') ? 'en' : storageLang;

const i18n = createI18n({
    globalInjection: true, //全局生效$t
    locale: c || localLang || 'en',
    messages,
    legacy: false,
})
export default i18n
