export default {
  getQuestion: {
    subTitle: "هل لديك سؤال",
    contact_link: {
      front: `الاطلاع على الأسئلة الشائعة في`,
      middle: "المساعدة",
      back: "صفحة"
    }
  },
  joinUs: {
    subTitle: "هل ترغب في الانضمام لفريق العمل لدينا؟",
    contact_link: {
      front: "أرسل السيرة الذاتية",
      back: "talent@txai.taxi"
    }
  }
}