export default {
  name: "User Agreement",
  list: [
    {
      title: "1. Special Note",
      details: {
        0: `1.1 Bayanat for Mapping and Surveying Services – Bayanat - PJSC is a
          company established under the laws of the United Arab Emirates under
          registration number CN-1139765 (“<strong>Bayanat</strong>”, the
          "<strong>Company</strong>" "<strong>we</strong>", “<strong>us</strong>”
          or “<strong>our</strong>”).`,
        1: `1.2 These Terms govern your use of the TAXI platform (the
          "<strong>Platform</strong>") and the mobile internet application
          (“<strong>App</strong>”) through which you can make an appointment for
          autonomous driving travel services (the "<strong>Service</strong>") and
          create a binding contract between you and us.`,
        2: `1.3 We ask you please read these Terms thoroughly so you properly
          understand your obligations and your rights in relation to our Services,
          especially the provisions involving significant interests, the time
          limit and method of performance of obligations such as safety
          precautions and risk warnings, civil liabilities (such as exemption
          provisions that exempt or limit the Company’s liabilities, provisions
          restricting your rights, and provisions on breach of contract), legal
          jurisdiction, dispute resolution, etc. Such provisions may be identified
          in bold, please read them carefully.`,
        3: `1.4 You shall also abide by the legal notices and personal information
          protection policies, fee standards, platform service rules and other
          regulations, notices and agreements (“Other Notices”) issued and
          modified by the Platform from time to time. You can view Other Notices
          on the platform. Before using the Service, you shall agree to accept
          these Terms.`,
        4: `1.5 By installing, accessing, downloading or otherwise using the
          Services and/or Platform, or any of their features you are agreeing to
          comply with and be bound by these Terms and Other Notices, and these
          Terms and Other Notices constitute legal documents that are legally
          binding on you. If you breach or violate any of the Terms, we have the
          right to block your access to the App and Platform immediately. If you
          do not agree to accept these Terms or any of their provisions or any
          content of Other Notices, you shall immediately stop the registration
          process.`,
        5: `1.6 You expressly agree that the Platform has the right to modify and
          publish these Terms from time to time in accordance with changes in laws
          and regulations and/or the Company’s operational needs. Any amendments
          to this Agreement will take effect within fifteen (15) days from the
          date of publication. For most changes we will notify you by way of
          advance announcement, push notification or pop-up window. You can also
          check the latest version of the modified agreement on the Platform at
          any time. However, we may make some kinds of change without telling you
          in advance – this includes where we are required to make a change due to
          legal and/or regulatory requirements and there is not time to give you
          notice of such changes.`,
        6: `1.7 If you do not agree to the modified content, you shall immediately
          stop using the Platform and the service. If you continue to use the App,
          Platform or Service, you will be deemed to accept the Company’s
          modification to these Terms.`,
        7: `1.8 To be eligible to use the Service, you must be at least 18 years of
          age or higher based on the age of majority in your jurisdiction. To
          satisfy us that you meet the above requirements, you must provide us
          with your Emirates ID and any other documentation we request.`
      }
    },
    {
      title: "2. Service Content",
      details: {
        0: `2.1. You will be able to call and use the autonomous driving car through
          the Platform. The “autonomous driving service” provided by the Platform
          refers to the fact that the speed information of moving objects is
          collected by intelligent software and a large number of sensing devices
          (including full coverage vehicle sensors with a 360-degree field of
          view, lidar, GPS, lasers, and cameras) equipped on the self-driving car,
          and the inertial navigation system configured in the computer will use
          the dead reckoning algorithm to continuously calculate the coordinates,
          azimuth, direction and speed of the car and its surrounding objects, so
          as to realise the service of autonomous and safe driving of the car.
          <br />
          When we receive your booking, we will dispatch a car according to our
          Platform. When the dispatch is successful, we will plan a route
          according to your designated location and send you to your destination.`,
        1: `2.2. You shall agree and confirm that we only provide the services
          stipulated in these Terms, and you shall take responsibility for
          providing the equipment related to the service (such as personal
          computers, mobile phones, and other devices related to access to the
          Internet or mobile networks).`,
        2: `2.3. You shall agree and confirm that these Terms also apply to other
          services provided by us or our affiliates through the Platform.`,
        3: `2.4. Any other services provided by a third party through the platform
          or via a link on the Platform will be independently provided by the
          third party, the responsibility shall be assumed by the third party
          accordingly, and the Company does not make any guarantee for this.`
      }
    },
    {
      title: "3. Rules of Use",
      details: {
        0: `3.1 After the user is successfully registered, the Company will give
          each user a user account, which is owned by the Company, and the user
          will obtain the right to use the account after completing the
          registration procedures. The right to use the account belongs only to
          the initial applicant, and any form of assignment is prohibited,
          including but not limited to giving away, borrowing, renting, transfer
          or sale. The user shall assume the responsibility for the custody of the
          account and password, and is fully responsible for all activities under
          the account and password (regardless of whether they are performed by
          the user or authorised by the user). Meanwhile, according to the
          relevant agreements of the Platform, you authorize us to share the
          user’s account information with our affiliates and other Bayanat
          platforms. You do not need to register another account to enjoy the full
          range of services provided by the platform.`,
        1: `3.2 The user shall not transfer or lend their account and password to
          others for use. If the user discovers that their account has been
          illegally used by others, he shall immediately notify the Company. All
          losses incurred by the illegal use of the account and password by others
          due to hacking or the user’s negligence of custody shall be assumed by
          the user himself, and the Company shall not assume any responsibility.
          If this causes losses to the Company, the user agrees to compensate for
          the Company’s losses. If you fail to properly keep your account and
          password, or violate this provision, allowing those without full
          capacity for civil conduct to use your account to ride alone, the
          obligations and responsibilities arising therefrom will be assumed by
          you. If this causes losses to others, you should assume the
          corresponding responsibility.`,
        2: `3.3 The user agrees that the Company has the right to place various
          commercial advertisements or any other types of commercial information
          in various ways in the process of providing services, and the user
          agrees that the Company can send product promotion or other related
          commercial information to the user via SMS, telephone, email or other
          methods. The said commercial advertisements or commercial information
          may come from a third party and are not part of the Company’s services.
          The user agrees that the Company is not responsible for the content of
          the said third-party advertisements or promotional information. The user
          shall prudently judge the correctness of the content and the product or
          service quality. If you do not agree to receive commercial
          advertisements or information, you can refuse to receive or unsubscribe
          them according to the instructions.`,
      }
    },
    {
      title: "4. Acceptable Use",
      details: {
        0: {
          firstLevel: `4.1 When using the Services, Platform and/or App, you must always do so
            for legal, authorized and acceptable purposes. You will not, nor permit
            any person using your account and/or accessing the Platform to:`,
          secondLevel: [
            `a. use the Services and/or the Website in any unlawful manner, for any
            unlawful purpose, or in any manner inconsistent with or in breach of
            these Terms;`,
            `b. violate or infringe the rights of Bayanat, any of its group
            companies, our users, or other third parties, including rights in
            respect of privacy, intellectual property, or other proprietary
            rights;`,
            `c. provide false, inaccurate or misleading information;`,
            `d. engage in any potentially fraudulent or suspicious activity and/or
            transactions, including receiving money which may be fraudulent or
            adding money to your Account which may be linked to criminal
            activities;`,
            `e. refuse to cooperate with an investigation or provide any
            information we request, including with respect to verifying your
            identity;`,
            `f. transmit any communication, material or other content that is
            illegal, obscene, defamatory, threatening, intimidating, harassing,
            hateful, racially or ethnically offensive, or which could instigate or
            encourage conduct that would be illegal or otherwise inappropriate,
            including promoting violent crimes;`,
            `h. seek to harm Bayanat, our group companies, or any of our users or
            customers; or`,
            `i. access, use, copy, modify, distribute, display, otherwise exploit
            or prepare derivative works based upon our Services in impermissible
            or unauthorized manners, or in ways that harm us, our group companies,
            our Services, systems, users, or others. In particular you must not,
            nor permit any person using your Account or accessing the Website to:`,
            `i. reverse engineer, alter, modify, create derivative works from,
            decompile, or extract code from our Services, except to the extent
            permitted by law;`,
            `ii. send, store, or transmit viruses or other harmful computer code
            through or onto our Services;`,
            `iii. gain or attempt to gain unauthorized access to our systems or
            those of our group companies;`,
            `iv. create accounts for our Services through unauthorized means;`,
            `v. collect the information of or about our users in any impermissible
            or unauthorized manner; and/or`,
            `vi. sell, resell, rent, or charge for the use of, our Services.`
          ]
        }
      }
    },
    {
      title: "5. Service Change, Suspension or Termination",
      details: {
        0: `5.1 You understand that the Company needs to inspect or maintain the
          service-providing platforms (such as internet websites and mobile
          networks) or related equipment on a regular or irregular basis, and that
          internet connection is restricted by existing uncertainties such as
          global network stability, technical status, user location and network
          used, power supply, government control, computer viruses, hacker
          attacks, etc. If the service is interrupted due to any of such
          circumstances, the Company does not need to assume any responsibility
          for this, and it shall give notice in advance except in special
          circumstances.`,
        1: {
          firstLevel: `5.2 In the event of any of the following circumstances, the Company has
            the right to suspend or terminate the provision of all or part of the
            services under these Terms to you at any time without any liability to
            you or any third party:`,
          secondLevel: [
            `a. The personal information provided by you is untrue;`,
            `b. You violate the usage rules stipulated in these Terms;`,
            `c. You behaviors violate laws, regulations or government regulations.`
          ]
        },
        2: `5.3 The user’s registered account or the user’s behavior and method of
          using the Platform shall not violate laws, regulations or national
          policy requirements, or infringe the legal rights and interests of any
          third party. If the said circumstance exists, the user shall bear the
          corresponding legal responsibility; meanwhile, the Company has the right
          to prohibit the user from continuing to use the account or stop the user
          from continuing to use the Platform, or provide relevant information to
          the government, courts or procuratorates in accordance with relevant
          laws or government orders. If the said circumstance causes any loss to
          the Company or a third party, the user shall be liable for compensation.`
      }
    },
    {
      title: "*6. Licenses and Intellectual Property *",
      details: {
        0: `6.1 Throughout our relationship with you, we retain ownership and/or
          control, as appropriate, of the Services, App and Platform and all
          intellectual property rights therein, including all copyrights,
          trademarks, domains, logos, trade secrets, patents, and other
          intellectual property rights that have existed, do, may or will exist in
          the future (“Intellectual Property”). You may not use our Intellectual
          Property unless permitted under these Terms or with our express written
          consent (which you can seek by contacting us using the contact details
          mentioned in these Terms).`,
        1: `6.2 Without the written consent of the Company, you shall not implement,
          use, transfer or license any third party to implement, use, or transfer
          the said intellectual property rights for any profit or non-profit
          purpose, and the Company reserves the right to investigate the said
          unauthorized acts.`,
        2: `6.3 In order for us to provide our Services and/or App to you to the
          best of our ability, you grant Bayanat a worldwide, free of charge,
          sublicensable, and transferable right to use, reproduce, distribute,
          create derivative works of, and display the information (including any
          content) that you upload, store, send, or receive on or through the use
          of the Services and/or Website. We will only use such rights for the
          limited purpose of providing our Services.`
      }
    },
    {
      title: "7. Authorization and License",
      details: {
        0: `7.1 On the premise that the user abides by these Terms, we grant the
          user a revocable, limited, non-exclusive, non-sublicensable and
          non-transferable license as follows: Download and install a copy of the
          application on a single mobile device owned or controlled by the user,
          and only run this copy of the application for the user’s own personal
          use.`,
        1: {
          firstLevel: `7.2 The user shall not:`,
          secondLevel: [
            `a. License, sublicense, sell, resell, transfer, assign, or distribute
            the service or application in any way, or otherwise conduct commercial
            development or provide it to any third party;`,
            `b. Edit, modify, compile, reversely engineer the service or
            application, or create derivative products accordingly;`,
            `c. Create an Internet “link” to the service, or “mirror” or “design”
            any application on any other server or wireless or Internet-based
            device;`,
            `d. Reverse engineer or access the application, design or construct
            competing products or services, use ideas or graphics similar to the
            service or application to design or construct products, or copy any
            ideas, features, functions or graphics of the service or application;`,
            `e. Start automatic programs or scripts, send multiple server requests
            per second, or excessively burden the service or application or hinder
            its work and/or performance; attempt to access websites, applications,
            services or related systems or networks without authorization;`,
            `f. Obstruct or disrupt the integrity or performance of websites,
            applications, services or the data contained therein;`,
            `g. Send or store materials containing software viruses, Trojan horses,
            worms or other harmful computer codes, scripts, files, agents or
            programs;`,
            `h. Send or store infringing, obscene, threatening, defamatory or other
            illegal materials, including materials that harm children or violate
            the privacy rights of third parties.`
          ]
        },
        2: `7.3 To the maximum extent permitted by laws and regulations, we have the
          right to investigate and review and bring a lawsuit for any of the said
          violations of laws and regulations. We can participate in and assist law
          enforcement agencies in prosecuting users who violate these Terms. If we
          believe that any content violates these Terms or otherwise harms the
          website, platform and/or the services or applications therein, we
          reserve the right to delete or disable access to such content at any
          time without notice.`
      }
    },
    {
      title: "8. Commitments and Guarantees",
      details: {
        0: {
          firstLevel: `8.1 The Company promises and guarantees as follows:`,
          secondLevel: [
            `a. The Company is established in accordance with the laws of the
            United Arab Emirates and has legal business qualifications;`,
            `b. The Company provides compulsory insurance and commercial for
            autonomous driving cars, and insures liability insurance for the
            personnel on the car.`
          ]
        },
        1: {
          firstLevel: `8.2 The user promises, acknowledges and guarantees as follows:`,
          secondLevel: [
            `a. to have sufficient independent legal capacity to accept the
            autonomous driving car travel service provided by the Company;`,
            `b. that the Company owns the intellectual property rights of test
            opinions, suggestions, ideas, plans and son on (collectively referred
            to as “Test Feedback”) put forward by users in the autonomous driving
            car travel service, and the user waives any rights to Test Feedback,
            and will not transfer or license the intellectual property rights of
            Test Feedback to any third party;`,
            `c. that to use the Services, Platform and/or App, the Company must
            collect, store, use, share and otherwise process your personal data.`,
            `d. During the use of the service, if any personal injury or property
            loss or damage to the Company and its employees, directors,
            shareholders and consultants, as well as third-party drivers
            dispatched to the Company occurs due to the user’s reasons, the user
            shall assume all related responsibilities and damages including
            attorney fees. If the Company makes compensation first, it will
            reserve the right to recover the compensation from the user.`
          ]
        }
      }
    },
    {
      title: "9. Limitation of Liability",
      details: {
        0: `9.1 Autonomous driving technology is still in the technological
          development stage. We provide services based on the current technical
          level and standard status in line with the industry development stage,
          and protect the health and personal and property safety of users, but
          there may still be immaturity, incompleteness, instability and other
          defects. Autonomous driving cars may currently face inherent technical
          risks, and according to the current technological level, the existence
          of defects cannot be found, including but not limited to computer
          program errors, sensor errors, computer processing and GPS positioning
          errors, and these additional risks may significantly increase the risk
          of accidents, causing accidents or death.`,
        1: `9.2 The Company shall not be liable for indirect, incidental, special,
          exemplary, punitive, or consequential damages, including lost profits,
          lost data, personal injury, or property damage related to, in connection
          with, or otherwise resulting from any use of the services, regardless of
          the negligence (either active, affirmative, sole, or concurrent) of the
          Company, even if uber has been advised of the possibility of such
          damages.`,
        2: `9.3 The Company shall not be liable for any damages, liability or losses
          arising out of: (i) your use of or reliance on the services or your
          inability to access or use the services; or (ii) any transaction or
          relationship between you and any third party provider, even if the
          Company has been advised of the possibility of such damages. the Company
          shall not be liable for delay or failure in performance resulting from
          causes beyond the Company‘s reasonable control.`,
        3: `9.4 The Company shall not be liable for any damages, liability or losses
          arising out of lack of or improper installation or use of child
          restraint systems for guests on rides requested through the services for
          whom a child restraint system is legally required.`,
        4:`9.5 The limitations and disclaimers in this section do not purport to
          limit liability or alter your rights as a consumer that cannot be
          excluded under applicable law. The Company’s liability shall be limited
          to the extent permitted by law. This provision shall have no effect on
          the Company’s choice of law provision set forth below.`,
        5: `9.6 The Company does not make any type of guarantee for the service,
          including but not limited to the timeliness, safety, and accuracy of the
          service, and the Company does not assume any responsibility for direct,
          indirect, incidental, special and subsequent damages and risks arising
          from the use or inability to use this service under any circumstances.`
      }
    },
    {
      title: "10. General",
      details: {
        0: {
          firstLevel: `In addition to any other terms and conditions within these Terms, please
          be aware of the following:`,
          secondLevel: [
            `<strong>Entire Agreement:</strong>
            these Terms make up the entire agreement between you and Bayanat
            regarding your use of the Services and/or Website.`,
            `<strong>Local Law Violations:</strong>
            our Services and Platform should not be accessed or otherwise used in
            any jurisdiction where any or all of the Services or Platform, as
            applicable, are prohibited to any degree.`,
            `<strong>Waiver:</strong>
            any waiver of any Terms or obligations or rights hereunder is not
            permitted without our written consent.`,
            `<strong>Assignment:</strong>
            we may assign or otherwise transfer all or some of our rights and
            obligations under these Terms to another party. You may not assign or
            otherwise transfer any of your rights and obligations under these
            Terms to another party.`,
            `<strong>Third Parties:</strong>
            except as stated or contemplated herein, these Terms do not give any
            third party any rights.`,
            `<strong>Severance: </strong>
            if any provision of these Terms is deemed illegal, unlawful, void, or
            for any reason unenforceable, then that provision shall be deemed
            severable from our Terms and shall not affect the validity and
            enforceability of the remaining provisions.`,
            `<strong>Notice:</strong>
            any notice you are required to give us under these Terms should be
            sent to <a href="mailto:services@txai.taxi">services@txai.taxi</a> .
            Any notice we are required to give to you will be sent to you via the
            App and/or via web announcements, mobile phone text messages or email;
            such notices are deemed to have been delivered to the recipient on the
            date of delivery.`,
            `<strong>Contact Information:</strong>
            You are responsible for informing us if your contact or identification
            information changes or confirming such information to us at our
            request.<br />-** Language**: These Terms were prepared in English and
            therefore the default language is English.`,
            `Complaints If you have are not satisfied with the Services, please get
            in touch with us by email at
            <a href="mailto:services@txai.taxi">services@txai.taxi</a>`
          ]
        },
      }
    },
    {
      title: "11. Dispute Resolution and Governing Law",
      details: {
        0: `We try to provide clear rules so that we can limit or hopefully avoid
          disputes between you and us. If a dispute does arise, however, please
          see the following as to how it can be resolved and what laws will apply.`,
        1: `Unless expressly required otherwise by law, these Terms and any dispute
          or claim (including non-contractual disputes or claims) arising out of
          or in connection with it or its subject matter or formation shall be
          governed by and construed in accordance with the law of England and
          Wales.`,
        2: `We both agree that the courts of the Abu Dhabi Global Market in Abu
          Dhabi shall have exclusive jurisdiction to settle any dispute or claim
          (including non-contractual disputes or claims) arising out of or in
          connection with these Terms or the subject matter or formation.`
      }
    },
  ]
}