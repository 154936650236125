import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_header_tabbar = _resolveComponent("header-tabbar");

  const _component_router_view = _resolveComponent("router-view");

  const _component_footer_tabbar = _resolveComponent("footer-tabbar");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_header_tabbar), _createVNode(_component_router_view, {
    style: _normalizeStyle(`min-height:${_ctx.minHeight}px`)
  }, null, 8, ["style"]), _createVNode(_component_footer_tabbar)], 64);
}