const ACT_GET_QUESTIONS = "ACT_GET_QUESTIONS";
const MUT_SET_QUESTIONS = "MUT_SET_QUESTIONS";
const ACT_GET_ARTICLE_LIST = "ACT_GET_ARTICLE_LIST";
const MUT_SET_ARTICLE_LIST = "MUT_SET_ARTICLE_LIST";
const MUT_SET_PAGE_LOAD = "MUT_SET_PAGE_LOAD";
export {
  ACT_GET_QUESTIONS,
  MUT_SET_QUESTIONS,
  ACT_GET_ARTICLE_LIST,
  MUT_SET_ARTICLE_LIST,
  MUT_SET_PAGE_LOAD,
};
