import { ref, Ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';
import store from "@/store/index";
import { ACT_GET_QUESTIONS, ACT_GET_ARTICLE_LIST } from "@/store/constance";

interface LangItem {
  src: string;
  name: string;
}
interface CarouselActive{
  handleClick:() =>void;
  activeLang:Ref<object>;
  langList:Array<LangItem>;
}

export const langeChange = ():CarouselActive => {
  const langList = reactive([
    {
      src: require("@/assets/home/ar.png"),
      name: 'العربية'
    },
    {
      src: require("@/assets/home/en.png"),
      name: 'English'
    }
  ])
  const i18n = useI18n();
  const activeLang = ref({});
  const handleClick = ():void => {
    if(localStorage.getItem("locale") === "ar"){
      activeLang.value = langList[0];
      localStorage.setItem("locale",'en');
      i18n.locale.value = 'en';
    }else {
      activeLang.value = langList[1];
      localStorage.setItem("locale",'ar');
      i18n.locale.value = 'ar';
    }
    store.dispatch(ACT_GET_QUESTIONS);
    store.dispatch(ACT_GET_ARTICLE_LIST);
    window.location.reload()
  }
  return {
    activeLang,
    langList,
    handleClick
  }
}