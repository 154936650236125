/*
 * @Author: wangyunchuan3410@163.com
 * @Date: 2022-07-15 14:10:42
 * @LastEditTime: 2022-07-15 20:13:54
 * @LastEditors: wangyunchuan3410@163.com
 * @Description:
 * @FilePath: \txai-officeweb\src\request\article.ts
 */
import { axiosRequest } from "./index";

export const articleList = <T>(params: {
  ename: string;
  get_detail: boolean;
  app_locale?: string;
}): Promise<T> => {
  return axiosRequest.request<T>({
    url: params.app_locale ? `/api/biz/article/list/${params.ename}?get_detail=true&app_locale=ar` : `/api/biz/article/list/${params.ename}?get_detail=true`,
    data: params,
  });
};

export const questionList = <T>(params: {
  ename: string;
  get_detail?: boolean;
  app_locale?: string;
}): Promise<T> => {
  return axiosRequest.request<T>({
    url: params.app_locale ? `/api/biz/article/list/${params.ename}?get_detail=true&app_locale=ar` : `/api/biz/article/list/${params.ename}?get_detail=true`,
    data: params,
  });
};

export const articleDetail = <T>(params: {
  id: string | string[];
  app_locale?: string;
}): Promise<T> => {
  return axiosRequest.request<T>({
    url: params.app_locale ? `/api/biz/article/${params.id}?app_locale=ar` : `/api/biz/article/${params.id}`,
  });
};
