import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "question-list",
  props: {
    questions: {
      type: Array
    }
  },

  setup() {
    const activeNames = ref(0);
    return {
      activeNames
    };
  }

});