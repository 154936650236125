import ZWRequest from "@/plugins/axios";

export const getBaseUrl = (host: any) => {
  console.log(host,'host')
  switch (host) {
    case 'txai-web-dev.kharita.ai':
      return {
        baseApi: 'https://api-dev.kharita.ai',
      }
    case 'txai-web-test.kharita.ai':
      return {
        baseApi: 'https://api-test.kharita.ai',
      }
    default:
      return {
        baseApi:'https://api.txai.taxi'
      }
  }
}

const axiosRequest = new ZWRequest({
  baseURL: getBaseUrl(location.host).baseApi,
  timeout: 15000,
  interceptors: {
    requestInterceptor: (config) => {
      return config;
    },
    responseInterceptor: (res) => {
      return res;
    },
  },
});
export { axiosRequest };