export default {
  pageTitle: "About TXAI",
  contentTitle: "TXAI by Bayanat",
  desc: "A G42 company, provides comprehensive world-class AI-powered geospatial intelligence to a growing number of sectors such as Defense, Environment, Energy & Resources, Smart Cities and Transportation.",
  intactDesc: `Its
    offering includes topographic, hydrographic and aeronautical products
    and charts, as well as spatial data surveying, analysis, management,
    modelling, visualization and cartography services. Bayanat’s solutions
    harness vast amounts of premium and unique data from a range of sources
    including Satellites, High Altitude Pseudo Satellites (HAPS) and Earth
    Observation all powered by AI to drive geospatial intelligence (gIQ). For
    more information, please visit:`
}