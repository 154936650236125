export function getLocalDate(timeStamp: number, offset = 8): number {
  const instance = new Date(timeStamp);
  const localTime = instance.getTime();
  const localOffset = instance.getTimezoneOffset() * 60000;
  const utc = localTime + localOffset; //utc即GMT时间
  return utc + 3600000 * offset;
}

export function getEnDate(time: string, format: string): string {
  const [day, dd, MM, yyyy]: string[] = time.split(" ");
  const maps: { [key: string]: string } = {
    day,
    dd,
    MM,
    yyyy,
  };
  Object.keys(maps).forEach((it) => {
    if (new RegExp("(" + it + ")", "g").test(format)) {
      format = format.replace(RegExp.$1, maps[it]);
    }
  });
  return format;
}
