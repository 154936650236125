import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-925f546a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "date"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  class: "read-more"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");

  return _openBlock(), _createElementBlock("dl", {
    class: "news-main-item-wrapper",
    onClick: _cache[0] || (_cache[0] = //@ts-ignore
    (...args) => _ctx.handleReadMore && _ctx.handleReadMore(...args))
  }, [_createElementVNode("dt", null, [_createVNode(_component_el_image, {
    src: _ctx.item?.cover,
    fit: "cover",
    style: {
      "width": "720px",
      "height": "400px"
    },
    loading: "lazy"
  }, null, 8, ["src"])]), _createElementVNode("dd", null, [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.dateString), 1), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.item?.title), 1), _createElementVNode("div", {
    class: "desc",
    innerHTML: _ctx.item?.content.replace(/<[^>]+>/g, '')
  }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$tm('common.readMore')), 1)])]);
}