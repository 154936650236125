export default {
  banner: {
    text_main: "تكساي",
    text_sub: "Robotaxis وأكثر من ذلك."
  },
  section_one: {
    list: [
      {
        src: require("@/assets/home/tab1.png"),
        title: "تاكسي بلمسة ذكاء اصطناعي!",
        details: "مزود بتقنية الذكاء الجيومكاني المُعزّزة بالذكاء الاصطناعي. تكساي على أهبة الاستعداد لإحداث نقلة جديدة في طريقة تجوالك حول المدينة."
      },
      {
        src: require("@/assets/home/tab2.png"),
        title: "مركبة صديقة للبيئة",
        details: "يمتلك أسطول TXAI بصمة كربونية منخفضة بسبب مزيج السيارات الكهربائية والهجينة."
      },
      {
        src: require("@/assets/home/tab3.png"),
        title: "السلامة أولاً",
        details: "سلامة الركاب هي أولويتنا القصوى. تأتي جميع مركبات TXAI مزودة بضابط سلامة سيرافق الركاب أثناء رحلتهم."
      }
    ]
  },
  section_two: {
    title: "استمتِع بسيارة Robotaxi من خلال بضع\nخطوات بسيطة",
    details: "اختر وجهتك، اطلب مركبة، اركب داخل مركبة تكساي واستمتع برحلتك."
  },
  section_three: {
    title: "تعال إلى جزيرة ياس وجزيرة السعديات لتجربة TXAI!",
    details: {
      front: "استمتع برحلات مجانية على متن مركبات تكساي لفترة محدودة!",
      back: "ترقبوا مواقع الاستلام والتوصيل الإضافية. قريبا."
    }
  },
  app_intro: {
    introList: [
      {
        index: 0,
        img_white: require("@/assets/home/map-white.svg"),
        img_black: require("@/assets/home/map-black.svg"),
        mediunImg: "",
        title: "أنواع خرائط مُتعددة",
        detail:
          "خريطة القمر الصناعي ، خريطة التضاريس ، الخريطة ثلاثية الأبعاد ، التجوّل الافتراضي ، خريطة الواقع المعزز ، الخريطة التفاعلية والمزيد.",
      },
      {
        index: 1,
        img_white: require("@/assets/home/search-white.svg"),
        img_black: require("@/assets/home/search-black.svg"),
        mediunImg: require("@/assets/home/search.png"),
        title: "البحث",
        detail:
          "البحث عن نقاط الاهتمام وعرض تفاصيل الموقع. يمكنك أيضًا التنقل مباشرة.",
      },
      {
        index: 3,
        img_white: require("@/assets/home/nav-white.svg"),
        img_black: require("@/assets/home/nav-black.svg"),
        mediunImg: require("@/assets/home/nav.png"),
        title: "تخطيط المسار والملاحة",
        detail:
          "يدعم تخطيط مسارات متعددة للقيادة والمشي وركوب الدراجات. يمكن للمستخدمين تحديد أفضل طريق يدويًا. بدوره عن طريق الملاحة والتوجيه الصوتي.",
      },
      {
        index: 2,
        img_white: require("@/assets/home/park-white.svg"),
        img_black: require("@/assets/home/park-black.svg"),
        mediunImg: require("@/assets/home/parking.png"),
        title: "موقف سيارات",
        detail:
          "ابحث بسهولة عن مواقف السيارات القريبة ، واعرض المواقع ، والتنقل بنقرة واحدة ، واعرض مواقع أماكن وقوف السيارات المجانية في الوقت الفعلي.",
      },
    ]
  },
  service_classification: {
    serviceList: [
      {
        index: 0,
        title: "روبو ميني باص",
        bgImg: require("@/assets/home/minibus.png"),
        content: [
          "حافلة صغيرة مستقلة وصديقة للبيئة تعمل بالطاقة النظيفة.",
          "من خلال تطبيقنا سهل الاستخدام ، يمكنك الوصول بسهولة إلى المعلومات في الوقت الفعلي حول كل خط حافلة للتخطيط لرحلتك. للركوب معنا ، ما عليك سوى ركوب الحافلة ومسح رمز الاستجابة السريعة ضوئيًا.",
          "اختبر مستقبل النقل مع Robo Minibus ، وانضم إلينا في التزامنا بعالم أنظف وأكثر اخضرارًا."
        ],
      },
      {
        index: 1,
        title: "اصطناعي",
        bgImg: require("@/assets/home/art.png"),
        content: [
          "حافلة تعمل بالكهرباء وصديقة للبيئة وذات سعة عالية يمكنها حمل ما يصل إلى 240 راكبًا.",
          "يوفر تطبيقنا سهل الاستخدام معلومات في الوقت الفعلي عن الجدول الزمني لـ ART ، والتأخيرات ، والاضطرابات ، مما يسهل عليك التخطيط لرحلتك. للركوب معنا ، ما عليك سوى ركوب الحافلة ومسح رمز الاستجابة السريعة ضوئيًا.",
          "اختبر مستقبل النقل العام مع ART ، وانضم إلينا في التزامنا بعالم أنظف وأكثر اخضرارًا."
        ],
      },
      {
        index: 2,
        title: "محطة شحن المركبات الكهربائية",
        bgImg: require("@/assets/home/charger.png"),
        content: [
          "نقرة واحدة تتيح الوصول إلى البيانات الديناميكية الدقيقة لمحطة شحن المركبات الكهربائية. يمكن استخدام المحطة 24 ساعة في اليوم لإعادة الشحن. طرق دفع متعددة متاحة.",
        ],
      },
    ]
  },
  section_four: {
    name: "غرفة الأخبار",
    readAll: "عرض جميع الأخبار",
    readMore: "اقرأ أكثر"
  },
  section_five: {
    name: "الأسئلة الشائعة",
    readAll: "عرض جميع الأسئلة"
  }
}