import { getLocalDate, getEnDate } from "./util";

export const dateToEnString: (timestamp: number) => string = (
  timestamp: number
): string => {
  if (timestamp) {
    const timeNumber = new Date(getLocalDate(timestamp, 12)).toUTCString();
    console.log(timeNumber);
    return getEnDate(
      new Date(getLocalDate(timestamp)).toUTCString(),
      "dd MM, yyyy"
    );
  }
  return "";
};
