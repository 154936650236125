export default {
  menuList: [
    {
      name: "الرئيسية",
      route: "/",
    },
    {
      name: "التنزيلات",
      route: "/download",
    },
    {
      name: "غرفة الأخبار",
      route: "/news",
    },
    {
      name: "حول",
      route: "/about",
    },
    {
      name: "المساعدة",
      route: "/help",
    },
  ],
  download: {
    title: "قم بتنزيل تطبيق تكساي للهواتف النقالة"
  },
  readMore: "اقرأ أكثر",
  need_help: {
    title: "هل مازلت بحاجه للمساعدة؟",
    contact: "اتصل بنا"
  },
  connect_channel: {
    title: "تواصل معنا عبر قنواتنا الاجتماعية"
  },
  footer: {
    copyright: "حقوق الطبع والنشر © 2022 المشروع من شركة بيانات. كافة الحقوق محفوظة",
    list: [
      {
        title: "منتجاتنا",
        link: [
          {
            path: "/download",
            name: "تطبيق تكساي للهواتف النقالة"
          }
        ]
      },
      {
        title: "المساعدة والدعم",
        link: [
          {
            path: "/help",
            name: "الأسئلة الشائعة"
          },
          {
            path: "/contact",
            name: "اتصل بنا"
          }
        ]
      },
      {
        title: "حول",
        link: [
          {
            path: "/about",
            name: "حول تكساي"
          },
          {
            path: "/news",
            name: "غرفة الأخبار"
          },
          {
            path: "/legal",
            name: "اتفاقية المستخدم"
          },
          {
            path: "/security",
            name: "سياسة الخصوصية"
          }
        ]
      },
    ]
  }
}