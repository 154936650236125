import about from "./about"
import common from "./common"
import contact from "./contact"
import download from "./download"
import help from "./help"
import home from "./home"
import newsroom from "./newsroom"
import privacyPolicy from "./privacy-policy"
import userAgreement from "./user-agreement"
export default {
  about,
  common,
  contact,
  download,
  help,
  home,
  newsroom,
  privacyPolicy,
  userAgreement
}
