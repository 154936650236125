import { defineComponent } from "vue";
export default defineComponent({
  name: "main-title",
  props: {
    name: {
      type: String,
      default: ""
    },
    align: {
      type: String,
      default: "center"
    },
    size: {
      type: Number,
      default: 38
    }
  }
});