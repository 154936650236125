/*
 * @Author: wangyunchuan3410@163.com
 * @Date: 2022-07-11 10:54:18
 * @LastEditTime: 2022-07-15 18:36:14
 * @LastEditors: wangyunchuan3410@163.com
 * @Description:路由
 * @FilePath: \txai-officeweb\src\router\index.ts
 */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import BasicLayout from "@/layout/basic.vue";
import store from "@/store/index";
import { ACT_GET_QUESTIONS, ACT_GET_ARTICLE_LIST } from "@/store/constance";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: BasicLayout,
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
      },
      {
        path: "/news",
        component: () =>
          import(
            /* webpackChunkName: "newslist" */ "../views/news-room/list.vue"
          ),
      },
      {
        path: "/news/:id",
        component: () =>
          import(
            /* webpackChunkName: "newsdetail" */ "../views/news-room/detail.vue"
          ),
      },
      {
        path: "/help",
        component: () =>
          import(/* webpackChunkName: "helplist" */ "../views/help/index.vue"),
      },
      {
        path: "/download",
        component: () =>
          import(
            /* webpackChunkName: "download" */ "../views/download/index.vue"
          ),
      },
      {
        path: "/contact",
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "../views/contact/index.vue"
          ),
      },
      {
        path: "/about",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/about-us/index.vue"),
      },
      {
        path: "/legal",
        component: () =>
          import(
            /* webpackChunkName: "legal" */ "../views/user-agreement/index.vue"
          ),
      },
      {
        path: "/security",
        component: () =>
          import(
            /* webpackChunkName: "security" */ "../views/privacy-policy/index.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(): { left: number; top: number } {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(() => {
  store.dispatch(ACT_GET_QUESTIONS);
  store.dispatch(ACT_GET_ARTICLE_LIST);
});
export default router;
