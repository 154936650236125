/*
 * @Author: wangyunchuan3410@163.com
 * @Date: 2022-07-11 11:59:24
 * @LastEditTime: 2022-07-13 19:12:31
 * @LastEditors: wangyunchuan3410@163.com
 * @Description:
 * @FilePath: \txai-officeweb\src\plugins\element.ts
 */
import {
  ElButton,
  ElCollapse,
  ElCollapseItem,
  ElCarousel,
  ElCarouselItem,
} from "element-plus";
export default (app: any) => {
  app.use(ElButton);
  app.use(ElCollapse);
  app.use(ElCollapseItem);
  app.use(ElCarousel);
  app.use(ElCarouselItem);
};
