/*
 * @Author: wangyunchuan3410@163.com
 * @Date: 2022-07-12 14:46:54
 * @LastEditTime: 2022-07-12 19:42:56
 * @LastEditors: wangyunchuan3410@163.com
 * @Description:全局组件注册
 * @FilePath: \txai-officeweb\src\components\index.ts
 */
import NeedHelp from "./need-help/index.vue";
import PlatButton from "./plat-button/index.vue";
import NewsMainItem from "./news-item/news-main-item.vue";
import NewsSubItem from "./news-item/news-sub-item.vue";
import MainTitle from "./main-title/index.vue";
import QuestionList from "./question-list/index.vue";
export default (app: any): void => {
  app.component(NeedHelp.name, NeedHelp);
  app.component(PlatButton.name, PlatButton);
  app.component(NewsMainItem.name, NewsMainItem);
  app.component(NewsSubItem.name, NewsSubItem);
  app.component(MainTitle.name, MainTitle);
  app.component(QuestionList.name, QuestionList);
};
