export default {
  banner: {
    text_main: "TXAI",
    text_sub: "Robotaxis and more."
  },
  section_one: {
    list: [
      {
        src: require("@/assets/home/tab1.png"),
        title: "A taxi with an AI twist!",
        details: "Powered by geospatial intelligence and layered with AI, TXAI is all set to change the way we commute."
      },
      {
        src: require("@/assets/home/tab2.png"),
        title: "Environmentally Friendly",
        details: "The TXAI fleet has a reduced carbon footprint due to its mix of electric and hybrid cars."
      },
      {
        src: require("@/assets/home/tab3.png"),
        title: "Safety First",
        details: "Passenger safety is our highest priority. All TXAIs come equipped with a safety officer who will escort passengers during their trip."
      }
    ]
  },
  section_two: {
    title: "Hail a Robotaxi with a few\nsimple steps",
    details: "Select your destination, request a ride, get in your TXAI, and enjoy the trip."
  },
  section_three: {
    title: "Come on down to Yas and Saadiyat Island to experience TXAI!",
    details: {
      front: "Enjoy free TXAI rides for a limited period!",
      back: "Stay tuned for additional pick up and drop off locations. Coming soon."
    }
  },
  app_intro: {
    introList: [
      {
        index: 0,
        img_white: require("@/assets/home/map-white.svg"),
        img_black: require("@/assets/home/map-black.svg"),
        mediunImg: "",
        title: "Multiple map types",
        detail:
          "Satellite map, terrain map, 3D map, Street View, AR map, Interactive map and more.",
      },
      {
        index: 1,
        img_white: require("@/assets/home/search-white.svg"),
        img_black: require("@/assets/home/search-black.svg"),
        mediunImg: require("@/assets/home/search.png"),
        title: "Search",
        detail:
          "Search points of interest and view location details. You can also navigate directly.",
      },
      {
        index: 3,
        img_white: require("@/assets/home/nav-white.svg"),
        img_black: require("@/assets/home/nav-black.svg"),
        mediunImg: require("@/assets/home/nav.png"),
        title: "Route Planning & Navigation",
        detail:
          "Supports multiple route planning for driving, walking and cycling. Users can manually select the best route. Turn by turn navigation and voice guidance.",
      },
      {
        index: 2,
        img_white: require("@/assets/home/park-white.svg"),
        img_black: require("@/assets/home/park-black.svg"),
        mediunImg: require("@/assets/home/parking.png"),
        title: "Parking",
        detail:
          "Easily search for nearby parking lots, view locations, one-click navigation, and view real-time free parking space locations.",
      },
    ]
  },
  service_classification: {
    serviceList: [
      {
        index: 0,
        title: "Robo Minibus",
        bgImg: require("@/assets/home/minibus.png"),
        content: [
          "An autonomous, eco-friendly minibus that runs on clean energy.",
          "With our user-friendly app, you can easily access real-time information about each bus line to plan your journey. To ride with us, simply board the bus and scan the QR code.",
          "Experience the future of transportation with Robo Minibus, and join us in our commitment to a cleaner, greener world.",
        ],
      },
      {
        index: 1,
        title: "ART",
        bgImg: require("@/assets/home/art.png"),
        content: [
          "An electrically powered, environmentally friendly, and high-capacity bus that can carry up to 240 passengers.",
          "Our user-friendly app provides real-time information on the ART’s timetable, delays, and disruptions, making it easy for you to plan your journey. To ride with us, simply board the bus and scan the QR code.",
          "Experience the future of public transportation with ART, and join us in our commitment to a cleaner, greener world.",
        ],
      },
      {
        index: 2,
        title: "EV Charging Station",
        bgImg: require("@/assets/home/charger.png"),
        content: [
          "One-click allows access to accurate dynamic data of EV charging station. The station can be used 24 hours a day for recharging. Multiple payment methods are available.",
        ],
      },
    ]
  },
  section_four: {
    name: "Newsroom",
    readAll: "View all news",
    readMore: "Read More"
  },
  section_five: {
    name: "Frequently Asked Questions",
    readAll: "View all questions"
  },
  
}