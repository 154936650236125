/*
 * @Author: wangyunchuan3410@163.com
 * @Date: 2022-07-11 10:54:18
 * @LastEditTime: 2022-07-19 18:34:32
 * @LastEditors: wangyunchuan3410@163.com
 * @Description:
 * @FilePath: \txai-officeweb\src\store\index.ts
 */
import { createStore } from "vuex";
import {
  ACT_GET_QUESTIONS,
  MUT_SET_QUESTIONS,
  ACT_GET_ARTICLE_LIST,
  MUT_SET_ARTICLE_LIST,
  MUT_SET_PAGE_LOAD,
} from "@/store/constance";
import { questionList, articleList } from "@/request/article";
import { responseType } from "@/types/global";
//分页方法
const setPageLoad = (state: any) => {
  if (state.page <= state.totalPage) {
    state.articlePageList = state.articleList.slice(
      0,
      (state.page == 1 ? 11 : 10) * state.page
    );
    state.page += 1;
  }
};
export default createStore({
  state: {
    questions: [],
    articleList: [],
    articlePageList: [],
    page: 1,
    totalPage: 1,
  },
  getters: {
    shotQuestions: (state) => state.questions.slice(0, 5),
    shopArticles: (state) => state.articleList.slice(0, 5),
    isLoaded: (state) => state.page > state.totalPage,
  },
  mutations: {
    [MUT_SET_QUESTIONS](state, questions): void {
      state.questions = questions;
    },
    [MUT_SET_ARTICLE_LIST](state, articleList): void {
      state.articleList = articleList;
      state.totalPage = Math.ceil(
        articleList.length / (state.page == 1 ? 11 : 10)
      );
      setPageLoad(state);
    },
    [MUT_SET_PAGE_LOAD](state): void {
      setPageLoad(state);
    },
  },
  actions: {
    //获取问题列表
    [ACT_GET_QUESTIONS]({ commit, state }) {
      // if (state.questions.length == 0) {
        const params:{
          ename: string;
          app_locale?: string;
        } = {
          ename: "services-and-help",
        }
        if(localStorage.getItem("locale") === "ar") {
          params.app_locale = 'ar';
        }
        questionList<responseType>(params).then((res) => {
          const { response, response_header } = res;
          if (response_header.status == 200) {
            commit(MUT_SET_QUESTIONS, response);
          }
        });
      // }
    },
    //获取新闻列表
    [ACT_GET_ARTICLE_LIST]({ commit, state }) {
      // if (state.articleList.length == 0) {
        const params:{
          ename: string;
          get_detail: boolean;
          app_locale?: string;
        } = {
          ename: "official-website",
          get_detail: false,
        }
        if(localStorage.getItem("locale") === "ar") {
          params.app_locale = 'ar';
        }
        articleList<responseType>(params).then((res) => {
          const { response, response_header } = res;
          if (response_header.status == 200) {
            commit(MUT_SET_ARTICLE_LIST, response);
          }
        });
      // }
    },
  },
  modules: {},
});
