import { defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const menuList = [{
      name: "Home",
      route: "/"
    }, {
      name: "Downloads",
      route: "/download"
    }, {
      name: "Newsroom",
      route: "/news"
    }, {
      name: "About",
      route: "/about"
    }, {
      name: "Help",
      route: "/help"
    }];
    const router = useRouter(); //点击Index

    const chooseItemIndex = ref(0); //点击MenuItem

    const handleItemClick = (item, index) => {
      chooseItemIndex.value = index;
      router.push(item.route);
    };

    watch(() => router.currentRoute.value.path, currentPath => {
      chooseItemIndex.value = menuList.findIndex(it => {
        if (it.route == currentPath) {
          return true;
        } else if (it.route != "/") {
          return !!currentPath.match(it.route);
        } else {
          return false;
        }
      });
    }, {
      immediate: true
    });
    return {
      menuList,
      handleItemClick,
      chooseItemIndex
    };
  }

});