import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { dateToEnString } from "@/utils/filter";
export default defineComponent({
  name: "news-sub-item",
  props: {
    item: {
      type: Object
    }
  },

  setup(props, context) {
    const router = useRouter();

    const handleReadMore = () => {
      router.push(`/news/${props.item.article_id}`);
      context.emit("click", props.item);
    };

    const dateString = computed(() => dateToEnString(props.item.published_at));
    return {
      handleReadMore,
      dateString
    };
  }

});