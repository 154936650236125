import { defineComponent, onMounted } from "vue";
import MenuList from "./components/menuList.vue";
import { langeChange } from '@/hooks/langChange';
export default defineComponent({
  name: "header-tab",
  components: {
    MenuList
  },

  setup() {
    let {
      langList,
      activeLang,
      handleClick
    } = langeChange();
    onMounted(() => {
      if (localStorage.getItem("locale") === "ar") {
        activeLang.value = langList[1];
        document.querySelector('html').dir = 'rtl';
        document.querySelector('html').style.direction = 'rtl';
        document.querySelector('html').lang = 'ar';
      } else {
        activeLang.value = langList[0];
        document.querySelector('html').dir = 'ltr';
        document.querySelector('html').style.direction = 'ltr';
        document.querySelector('html').lang = 'en';
      }
    });
    return {
      activeLang,
      langList,
      handleClick
    };
  }

});