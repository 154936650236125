// 封装类
import axios from "axios";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

interface ZWRequestInterceptors {
  requestInterceptor?: (config: AxiosRequestConfig) => AxiosRequestConfig; // 这是个函数类型
  requestErrorInterceptor?: (error: any) => any;
  responseInterceptor?: (config: AxiosResponse) => AxiosResponse;
  responseErrorInterceptor?: (error: any) => any;
}
interface ZWRequestConfig extends AxiosRequestConfig {
  interceptors?: ZWRequestInterceptors;
}
class ZWRequest {
  instance: AxiosInstance;
  interceptors?: ZWRequestInterceptors;
  constructor(config: ZWRequestConfig) {
    this.instance = axios.create(config);
    this.interceptors = config.interceptors;
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestErrorInterceptor
    );
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseErrorInterceptor
    );
    this.instance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return error;
      }
    );
    this.instance.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      }
    );
  }
  request<T>(config: ZWRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      // 对单独请求传来的拦截器进行处理
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors?.requestInterceptor(config);
      }
      this.instance
        .request<any, any>(config)
        .then((res): void => {
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors?.responseInterceptor(res);
          }
          resolve(res.data);
        })
        .catch((error): void => {
          reject(error);
        });
    });
  }
  get<T>(config: ZWRequestConfig): Promise<T> {
    return this.request<T>({ ...config, method: "GET" });
  }
  post<T>(config: ZWRequestConfig): Promise<T> {
    return this.request<T>({ ...config, method: "POST" });
  }
  delete<T>(config: ZWRequestConfig): Promise<T> {
    return this.request<T>({ ...config, method: "DELETE" });
  }
}
export default ZWRequest;
