export default {
  getQuestion: {
    subTitle: "Got a question",
    contact_link: {
      front: `See the FAQs on the `,
      middle: "Help",
      back: "page"
    }
  },
  joinUs: {
    subTitle: "Want to work with us?",
    contact_link: {
      front: "Send your CV to",
    }
  }
}