import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "collapse-part-title"
};
const _hoisted_2 = {
  class: "collapse-part-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_collapse_item = _resolveComponent("el-collapse-item");

  const _component_el_collapse = _resolveComponent("el-collapse");

  return _openBlock(), _createBlock(_component_el_collapse, {
    modelValue: _ctx.activeNames,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.activeNames = $event),
    class: "collapse-wrapper"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (item, index) => {
      return _openBlock(), _createBlock(_component_el_collapse_item, {
        name: item.content,
        key: `question-${index}`
      }, {
        title: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString(item.title), 1)]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString(item.content), 1)]),
        _: 2
      }, 1032, ["name"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"]);
}