import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7a4177a7"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "menu-tab-list-wrapper"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$tm('common.menuList'), (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(['menu-item', {
        active: _ctx.chooseItemIndex == index
      }]),
      key: `menu-item-${index}`,
      onClick: $event => _ctx.handleItemClick(item, index)
    }, _toDisplayString(item.name), 11, _hoisted_2);
  }), 128))]);
}