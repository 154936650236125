export default {
  name: "سياسة الخصوصية",
  list: [
    {
      title: "",
      details: {
        0: `<strong>بيانات لخدمات الخرائط والمسح</strong> (“we,” “our,” or “us”) ("نحن" أو "إننا" أو "لنا") نقدم خدمة سيارات الأجرة بدون سائق لمستخدمي [<a
            href="https://txai.taxi"
            target="_blank"
            >https://txai.taxi/</a
          >] ("الموقع الالكتروني") و/أو مستخدمو تطبيقات تكساي للهواتف الجوالة ("التطبيق") (ويشار إليها مجتمعة "بالخدمة"). تقدم سياسة الخصوصية هذه وصفاً لأنواع المعلومات التي نجمعها من خلال الخدمة وكيف نستخدم هذه المعلومات ولمن وتحت أي ظروف قد نفصح عنها.
          <strong>من خلال الدخول على الخدمة من أي جهاز كمبيوتر أو هاتف جوال أو جهاز لوحي أو أي جهاز آخر (ويشار إليها مجتمعة "بالجهاز")، أو تعامل مع تكساي بأي طريقة أخرى، فإنك توافق على شروط سياسة الخصوصية هذه. إذا كنت غير موافق على هذه سياسة الخصوصية، يرجى عدم استخدام المواقع.</strong>`,
        1: `يجوز لنا تعديل سياسة الخصوصية هذه في أي وقت، وسوف نقوم بنشر الإصدار الحالي على مواقعنا الإلكترونية. نحن نشجعك على مراجعة سياسة الخصوصية الخاصة بنا بشكل دوري لكي تبقى على اطلاع على كيفية استخدامنا للمعلومات التي نجمعها.`
      }
    },
    {
      title: "اتصل بنا",
      details: {
        0: `إذا كانت لديك أسئلة أو استفسارات بشأن سياسة الخصوصية هذه أو كيف نقوم بجمع واستخدام معلومات عملائنا، يمكنك الاتصال بنا عن طريق مراسلتنا عبر البريد الإلكتروني على [<a href="mailto:support@txai.ae"> support@txai.ae </a>]، أو عن طريق العنوان البريدي على [2458، 24، برج السيلا، مربعة سوق أبوظبي العالمي، جزيرة المرايا، أبوظبي، الإمارات العربية المتحدة]؛ عناية: طلب الخصوصية.`,
        1: `إذا احتجنا أو طُلب منا الاتصال بك بشأن أي أمر يتعلق بمعلوماتك، فقد نقوم بذلك عن طريق البريد الإلكتروني أو الهاتف أو خدمة البريد.`
      }
    },
    {
      title: "حسابات المستخدم والدخول على الخدمات",
      details: {
        0: `يرجى ملاحظة أنه عند استخدامك لخدمتنا، ستقوم تكساي بإنشاء حساب ("الحساب" أو "حساب تكساي"). ستجمع تكساي المعلومات الخاصة بك وتستخدمها، بما في ذلك البيانات الوصفية (كما هو موضح أدناه)، وفقًا لسياسة الخصوصية هذه.`,
      }
    },
    {
      title: "عند تسجيل حسابك:",
      details: {
        0: {
          firstLevel: ``,
          secondLevel: [
            `ستقوم تكساي بربط جميع معلوماتك بحسابك المسجل. وهذا يشمل المعلومات المتسلمة من جميع الأجهزة والمتصفحات التي لقد اخترت تسجيل الدخول منها.`,
            `عند استخدام الخدمة، سيكون موقعك التقريبي مرئيًا للمستخدمين الآخرين المرتبطين بهذا الحساب.`,
            `سيكون حسابك والمعلومات المرتبطة بحسابك مرئية فقط لتكساي.`,
            `قد تنشئ وتطلب تكساي من وقت لآخر وسائل تعريف وتحقّق إضافية أو مختلفة وتفويض لتسجيل الدخول والدخول إلى الخدمات أو للدخول إلى خصائص محددة أو أقسام معينة من الخدمات.`,
            `بيانات تسجيل الدخول الخاصة بك هي مسؤوليتك. أنت مسؤول مسؤولية كاملة عن أي استخدام أو سوء استخدام لحسابك وبياناتك الشخصية نتيجة إبلاغ بيانات تسجيل الدخول الخاصة بك لشخص آخر.`
          ]
        }
      }
    },
    {
      title: "المعلومات التي قد نجمعها منك",
      details: {
        0: `يقصد بمصطلح "المعلومات الشخصية" في سياسة الخصوصية هذه، المعلومات التي تقدمها لنا والتي تحدد هويتك الشخصية، مثل اسمك وعنوان بريدك الإلكتروني، وفي ظروف معينة، معلومات موقعك ومسارك ومعلومات أخرى يمكن ربطها بشكل معقول بهذه المعلومات من قبل تكساي.`,
        1: `نجمع معلومات شخصية منك عندما ترسلها إلينا ،
          بما في ذلك عن طريق: (1) تسجيل حسابك ؛ (2) الاتصال بنا ؛ (3) استخدام
          الخدمة أو (4) تختار مشاركة المعلومات. هذه المعلومة
          قد تشمل ، على سبيل المثال ، اسمك وعنوان بريدك الإلكتروني والموقع والبريد
          العنوان ، عنوان العمل ، رقم الهاتف ، تاريخ الميلاد ، الجنس ، الملف الشخصي
          الصورة ودفتر العناوين الخاص بك وبيانات الاستخدام وملفات تعريف الارتباط ومعلومات الجهاز.
          قد نقوم بدمج المعلومات التي نجمعها منك مع المعلومات التي نجمعها
          تلقي معلومات عنك من مصادر أخرى ، مثل خدمات تحديث العنوان.
          ستقوم خوادمنا بتخزين معلوماتك الشخصية حتى يتم حذفها في
          وفقًا لممارسات الاحتفاظ لدينا والمفصلة أدناه ،
          الاحتفاظ بالمعلومات الشخصية". "`,
        2: `في بعض الولايات القضائية، قد يكون للأفراد الحق في الموافقة أو سحب الموافقة على استخدامات معينة. إذا كنت تقيم في مثل هذه الولايات القضائية، فقد يكون لديك حقوق إضافية موضحة تفصيلاً أدناه، "الدخول والتصحيح والحذف".`
      }
    },
    {
      title: "المعلومات التي نجمعها بشكل تلقائي",
      details: {
        0: {
          firstLevel: `إننا نجمع معلومات حول نشاطك في الخدمة، والتي نستخدمها لصيانة وتحسين خدمتنا. يتضمن ذلك أشياء مثل العثور على المسار المفضل بالنسبة لك واقتراح الوجهات المفضلة أو الوجهات التي تسافر إليها عادة لتبسيط بحثك وسفرك. قد تتضمن معلومات النشاط التي نجمعها ما يلي:`,
          secondLevel: [
            `معلومات مفصلة عن الموقع والرحلة والمسار. يتم جمع معلومات الموقع والمسار على سبيل المثال في شكل إشارات جي بي أس (GPS) (مقترنة بطابع زمني) وعنوان برتوكول الانترنت (IP) ومعلومات أخرى من أجهزة الاستشعار وأجهزة الاستقبال الموجودة على جهازك أو حوله، حيث تم تثبيت التطبيق وتنشيطه. يتم حفظ معلومات الموقع والمسار هذه في سجل المسار لجميع الرحلات التي قمت بها أثناء استخدام التطبيق. تعتمد أنواع بيانات الموقع التي نجمعها جزئيًا على إعدادات جهازك وحسابك. على سبيل المثال، بناءً على إعدادات جهازك المختارة و/أو إعدادات حسابك، قد نقوم بجمع معلومات حول موقعك والرحلة (أو عدمها) وكذلك أثناء عدم استخدام التطبيق، حتى نتمكن من إخبارك بموعد المغادرة لرحلات مخطط لها، وإعلامك بحالة ازدحام مروري في منطقتك، وتزويدك بالمساعدة في صف السيارة، وتحليل مدى فعالية إعلاناتنا وأكثر؛`,
            `المصطلحات أو الأماكن التي بحثت عنها؛`
          ]
        }
      }
    },
    {
      title: "البيانات الوصفية (معلومات حول جهازك والمتصفح واستخدام التطبيق):",
      details: {
        0: {
          firstLevel: `تجمع تكساي معلومات حول استخدام الخدمة والمعلومات من الجهاز الذي قمت بتثبيت تكساي عليه أو من المتصفح الذي تستخدم خدمتنا منه. فمثلا:`,
          secondLevel: [
            `قد تقوم تكساي بجمع وتسجيل عدد المرات التي تستخدم فيها خدمتنا ولأي مدة ونوع جهازك والمعرفات الفريدة ونوع وإصدار نظام التشغيل واستخدام البطارية وعنوان بروتوكول الإنترنت (IP) واسم المجال الذي يخدمك للدخول على الخدمة، والموقع الجغرافي للجهاز الذي تستخدمه لتسجيل الدخول واستخدام تكساي.`,
            `نقوم أيضًا بجمع معلومات حول تفاعل أجهزتك مع خدمتنا، بما في ذلك تقارير الأعطال ونشاط النظام والتاريخ والوقت وعنوان محدد الموارد الموحد (URL) المحيل لطلبك.`
          ]
        }
      }
    },
    {
      title: "استخدام المعلومات",
      details: {
        0: `يجوز لنا استخدام المعلومات التي نجمعها منك وعنك لأي من الأغراض التالية: (1) تقديم خدمتنا؛ (2) صيانة وتحسين الخدمة؛ (3) تقديم خدمات ذات طابع شخصي. على سبيل المثال ، قد تستخدم تكساي معلومات "المنزل" و "العمل" ومعلومات "المفضلة" الأخرى (إذا اخترت تقديم هذه المعلومات) لتقديم مسارات مقترحة بناءً على الطريقة التي اخترتها للسفر بين الوجهات المفضلة في الماضي.؛ (4) مراجعة استخدام وعمليات الموقع؛ (5) معالجة المشاكل في الموقع الإلكتروني أو أعمالنا أو خدمتنا؛ (6) حماية أمن وسلامة الموقع وأعمالنا؛ (7) مراقبة الموقع الإلكتروني من حيث الامتثال لشروط الاستخدام والقوانين؛ و (8) الاتصال بك بخصوص تحديثات الخدمة والمواد الإعلامية والترويجية الأخرى سواء منا أو من شركائنا الموثوق بهم، وكذلك من الشركات الأخرى.`,
        1: `إذا قمت بالتسجيل في قائمتنا البريدية، فسنرسل لك رسائل إعلامية بالبريد الإلكتروني حول عروض الخدمات. يمكنك "إلغاء الاشتراك"، في أي وقت، بنفسك من قائمة البريد الإلكتروني الخاصة بنا ببساطة عن طريق النقر على الزر "إلغاء الاشتراك".`,
        2: `إذا كنت لا ترغب في تلقي مراسلات منا حول العروض الخاصة والعروض الترويجية، يمكنك إلغاء الاشتراك في تلقي هذه المراسلات باتباع الإرشادات الواردة في الرسائل التي تتلقاها. حتى إذا قمت بإلغاء الاشتراك في تلقي هذه الرسائل، فإننا نحتفظ بالحق في إرسال رسائل معينة إليك تتعلق بالخدمات التي نقدمها، وقد نرسل إليك إعلانات الخدمة والرسائل الإدارية. نحن لا نقدم لك إمكانية إلغاء الاشتراك في تلقي تلك هذه المراسلات.`
      }
    },
    {
      title: "الكشف عن المعلومات",
      details: {
        0: `قد نقوم بالكشف عن المعلومات التي تم جمعها منك وعنك على النحو التالي: (1) بموافقتك (2) لشركاتنا ومقدمي الخدمات ذوي الصلة، لأداء وظيفة دعم تجاري أو مهني أو فني لنا؛ (3) لشركاء التسويق أو المعلنين أو الجهات الخارجية الأخرى الخاصة بنا، الذين قد يتصلون بك لتقديم العروض الخاصة بهم؛ (4) حسب الضرورة إذا كنا نعتقد بأنه قد حدث انتهاك لشروط الاستخدام أو حقوقنا أو حقوق أي طرف ثالث؛ (5) استجابة للإجراءات القانونية (مثل أمر تفتيش أو أمر استدعاء أو أمر محكمة) وتقديم معلومات لوكالات إنفاذ القانون أو فيما يتعلق بالتحقيق في الأمور المتعلقة بالسلامة العامة، وفقًا لما يسمح به القانون، أو خلافًا لذلك وفقًا لما يقتضيه القانون؛ و (6) في حالة الاستحواذ على شركتنا أو على جميع أصولها إلى حد كبير، قد تكون معلوماتك الشخصية أحد الأصول المنقولة. قد نكشف أيضًا عن معلوماتك الشخصية بموافقتك الصريحة. يجوز لنا مشاركة معلومات تعريفية مجمعة غير شخصية عن مستخدمي الخدمة مع أطراف ثالثة. يرجى ملاحظة أنه إذا قمت طواعية بتقديم أي معلومات شخصية للنشر على الموقع الإلكتروني، مثل تعليق أو تدوينة، فإن المعلومات تصبح متاحة للجمهور ويمكن جمعها واستخدامها من قبل الآخرين، لذلك يجب عليك توخي الحذر قبل نشر معلومات عنك عبر الإنترنت.`
      }
    },
    {
      title: "الدخول والتصحيح والحذف",
      details: {
        0: `نحن نحترم حقك في الدخول إلى معلوماتك الشخصية وتصحيحها. يمكنك الدخول إلى معلوماتك الشخصية عن طريق تسجيل الدخول إلى حسابك. من هناك، يمكنك تصحيح أو تعديل معلوماتك. يمكنك أيضًا ممارسة حقوقك، وفقًا للقوانين المعمول بها، بأن تطلب منا حذف أو تقييد الدخول إلى بياناتك الشخصية. والتي قد نحتاج للاحتفاظ بها للأغراض المسموح بها قانونًا وسيتم شرح ذلك لك إذا لزم الأمر. إذا كنت بحاجة إلى مساعدة في تصحيح أو تحديث معلوماتك الشخصية، أو ترغب في أن تطلب منا حذف معلوماتك الشخصية، يرجى الاتصال بنا باستخدام معلومات الاتصال الواردة تفصيلاً في قسم "اتصل بنا".`
      }
    },
    {
      title: "الاحتفاظ بالمعلومات الشخصية",
      details: {
        0: `يتم الاحتفاظ بالمعلومات الشخصية التي نجمعها أو نقوم بالدخول عليها أو نعالجها فقط طالما كان ذلك ضروريًا لتحقيق الأغراض التي تم جمعها من أجلها، ما لم ينص على خلاف ذلك في الاتفاقيات بينك وبين تكساي أو كما هو مطلوب أو مصرح به بموجب القانون. فإن المعلومات الشخصية، التي لم تعد مطلوبة لتحقيق أغراض محددة، يتم إتلافها أو محوها أو جعلها مجهولة الهوية.`
      }
    },
    {
      title: "الأمن",
      details: {
        0: `إننا نطبق تدابير معقولة ومناسبة مصممة للحفاظ على المعلومات التي نجمعها بطريقة آمنة. لقد اتخذنا خطوات مادية وإلكترونية وإدارية محددة لحماية وتأمين المعلومات التي نجمعها من زوار المواقع الإلكترونية. وعلى الرغم من أننا نتبع إجراءات معقولة لمحاولة حماية المعلومات التي بحوزتنا، فلا يوجد نظام أمن مثالي ولا يمكننا أن نعد، ولا يجب أن تتوقع، أن معلوماتك ستكون آمنة في جميع الظروف.`
      }
    },
    {
      title: "الأطفال",
      details: {
        0: `الخدمة ليست موجهة للأطفال، كما أننا لا نجمع عن قصد أي معلومات شخصية من الأطفال دون سن الثالثة عشرة دون موافقة الوالدين التي يمكن التحقق منها. إذا كنت تعتقد أن طفلًا قد قدم لنا معلومات شخصية، فيرجى الاتصال بنا على الفور كما هو موضح أدناه، وسنحاول جاهدين القيام بالتحقيق في الأمر وحذف هذه المعلومات من أنظمتنا.`
      }
    },
    {
      title: "المواقع الإلكترونية للأطراف الثالثة",
      details: {
        0: `قد تحتوي الخدمة على روابط لمواقع أطراف ثالثة، مثل مواقع التواصل الاجتماعي مثل فايسبوك وتوتير، والتي قد تحتوي على سياسات خصوصية تختلف عن سياساتنا. نحن لسنا مسؤولين عن الأنشطة والممارسات التي تحدث على هذه المواقع. وفقًا لذلك، نوصيك بمراجعة سياسة الخصوصية المنشورة على أي موقع خارجي قبل الإفصاح عن أي معلومات شخصية. يرجى الاتصال بهذه المواقع مباشرة إذا كان لديك أي أسئلة حول سياسات الخصوصية الخاصة بهم.`
      }
    },
    {
      title: "التغييرات في سياسة الخصوصية هذه",
      details: {
        0: `قد نقوم بتغيير سياسة الخصوصية هذه من وقت لآخر، بما في ذلك كما يتطلب لمواكبة القواعد واللوائح والتقنيات الجديدة ومعايير الأمان. عندما نقوم بذلك، سوف نقوم بنشر التغيير (التغييرات) على موقعنا. إذا قمنا بتغيير السياسة بشكل جوهري وبأثر رجعي، سنقدم لك إشعارًا مناسبًا.`
      }
    },
    {
      title: "حقوق الخصوصية الخاصة بك في كاليفورنيا",
      details: {
        0: `إذا كنت مقيماً في ولاية كاليفورنيا، بالولايات المتحدة الأمريكية، فإن قانون كاليفورنيا يسمح لعملائنا المقيمين في كاليفورنيا بطلب معلومات معينة حول كشفنا عن المعلومات الشخصية إلى الأطراف الثالثة لأغراض التسويق المباشر الخاصة بهم خلال السنة التقويمية السابقة. هذا الطلب مجاني ويمكن إجراؤه مرة واحدة في السنة. لتقديم مثل هذا الطلب، يرجى مراسلتنا على العنوان التالي: [مشروع تكساي، الطابق 3، بناية مكاتب وفرة سكوير، نجمة أبوظبي، أبوظبي] عناية: طلب خصوصية. الحق في حذف معلومات منشورة - القاصرون في كاليفورنيا.`,
        1: `إذا كان عمرك أقل من 18 عامًا، وتقيم في كاليفورنيا بالولايات المتحدة الأمريكية ولديك حساب مسجل لدينا، فيحق لك طلب حذف المعلومات غير المرغوب فيها التي تنشرها علنًا على الخدمة. لطلب حذف هذه المعلومات، يمكنك الاتصال بنا على النحو المبين أدناه. عند تلقي مثل هذا الطلب، سوف نتأكد من أن المعلومات ليست متاحة للجمهور على الخدمة، ولكن قد لا يتم حذف المعلومات بشكل كامل أو شامل من أنظمتنا وقواعد بياناتنا.`
      }
    },
    {
      title: "نقل البيانات دولياً",
      details: {
        0: `تعمل تكساي عالميًا، هذا يعني أنه قد يتم تخزين البيانات الشخصية ومعالجتها (على سبيل المثال تخزينها في مركز بيانات) في أي دولة حيث نمتلك نحن أو مقدمو الخدمة لدينا مرافق أو ننظم فعاليات. بموجب استخدام تكساي أو تقديم البيانات الشخصية لأي من الأغراض المذكورة أعلاه، فإنك تقر بأنه قد يتم نقل بياناتك الشخصية أو تخزينها في أبو ظبي أو في دول أخرى حول العالم. قد يكون لهذه الدول قواعد حماية بيانات مختلفة أو أقل حماية من تلك المعمول بها في دولتك. قد تكون معلوماتك متاحة للسلطات الحكومية بأمر القانون ووفقاً للقانونين المعمول بها في مثل هذه السلطات القضائية. باستخدام الخدمة و/أو تقديم المعلومات الشخصية لنا، فإنك توافق على نقل معلوماتك إلى منشآتنا كما هو موضح في سياسة الخصوصية هذه.`,
        1: {
          firstLevel: `إذا كنت مقيمًا في المنطقة الاقتصادية الأوروبية (EEA)، وتم نقل بياناتك الشخصية خارج المنطقة الاقتصادية الأوروبية، فسنقوم بما يلي:`,
          secondLevel: [
            `معالجتها في منطقة جغرافية قررت المفوضية الأوروبية بأنها توفر مستوى مناسبًا من الحماية للمعلومات الشخصية؛ أو`,
            `تطبيق الضمانات المناسبة لحماية معلوماتك الشخصية، بما في ذلك نقلها وفقًا لآلية النقل المعمول بها، بما في ذلك الشروط التعاقدية القياسية للمفوضية الأوروبية.`
          ]
        }
      }
    },
    {
      title: "حقوق الخصوصية الأوروبية",
      details: {
        0: {
          firstLevel: `إذا كنت تقيم في المنطقة الاقتصادية الأوروبية، فقد يكون لك الحق في ممارسة بعض حقوق الخصوصية المتاحة لك بموجب القوانين المعمول بها. سنقوم بمعالجة طلبك وفقًا لقوانين حماية البيانات المعمول بها. قد نحتاج إلى الاحتفاظ بمعلومات معينة لأغراض حفظ السجلات أو لإكمال المعاملات التي بدأتها قبل طلب أي حذف.`,
          secondLevel: [
            `الحق في عدم تقديم الموافقة أو سحب الموافقة. قد نسعى إلى الاعتماد على موافقتك من أجل معالجة بيانات شخصية معينة. عند قيامنا بذلك، يحق لك عدم تقديم موافقتك أو سحب موافقتك في أي وقت. إن هذا لا يؤثر على قانونية معالجة البيانات اعتماداً على موافقتك قبل سحبها.`,
            `حق الدخول و/أو إمكانية النقل. قد يكون لك الحق في الدخول إلى البيانات الشخصية التي نحتفظ بها عنك، وفي بعض الحالات المحدودة، توفير هذه البيانات لك حتى تتمكن من تقديمها أو "نقل" تلك البيانات إلى مزود آخر.`,
            `حق محو البيانات. في ظروف معينة، قد يكون لك الحق في محو البيانات الشخصية التي نحتفظ بها عنك (على سبيل المثال إذا لم تعد ضرورية للأغراض التي تم جمعها من أجلها في الأساس).`,
            `الحق في الاعتراض على معالجة البيانات. قد يكون لك الحق في أن تطلب من تكساي التوقف عن معالجة بياناتك الشخصية و/أو التوقف عن إرسال رسائل تسويقية لك.`,
            `حق التصحيح. قد يكون لك الحق في مطالبتنا بتصحيح أي معلومات شخصية غير صحيحة أو غير كاملة.`,
            `الحق في تقييد معالجة البيانات. قد يكون لك الحق في طلب تقييد معالجة بياناتك الشخصية في ظروف معينة (على سبيل المثال، عندما تعتقد أن البيانات الشخصية التي نحتفظ بها عنك غير صحيحة أو محتفظ بها بشكل قانوني).`,
            `الحق في تقديم شكوى إلى هيئة حماية البيانات المحلية. إذا كنت مقيمًا في المنطقة الاقتصادية الأوروبية، فيحق لك تقديم شكوى إلى سلطة حماية البيانات بشأن جمعنا لبياناتك الشخصية واستخدامها.`
          ]
        }
      }
    },
    {
      title: "الحق في تقديم الشكاوى",
      details: {
        0: `إننا نتحلى بالشفافية بشأن الطرق التي نجمع بها المعلومات الشخصية ونستخدمها، ونرحب بتلقي أي اسئلة أو استفسارات لك. إذا كانت لديك أي مخاوف أو شكاوى بشأن الطريقة التي نتعامل بها مع معلوماتك الشخصية، فيرجى الاتصال بنا على النحو الموضح أعلاه. إلى الحد الذي تعتقد فيه بأننا لم نعالج مخاوفك أو بخلاف ذلك نختار القيام بذلك، يحق لك تقديم شكوى إلى سلطة إشرافية في الدولة الذي تقيم فيها.`
      }
    },
  ]
}