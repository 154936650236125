import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import elementPlus from "./plugins/element";
import globalComponent from "@/components/index";
import i18n from "@/lang"
import "@/styles/element-vars.scss";
const app = createApp(App);
app.use(i18n)
elementPlus(app);
globalComponent(app);
app.use(store).use(router).mount("#app");
