import { defineComponent } from "vue";
import HeaderTabbar from "@/components/header/index.vue";
import FooterTabbar from "@/components/footer/index.vue";
export default defineComponent({
  name: "basic-layout",
  components: {
    HeaderTabbar,
    FooterTabbar
  },

  setup() {
    const minHeight = window.innerHeight - 447;
    return {
      minHeight
    };
  }

});