export default {
  name: "Privacy policy",
  list: [
    {
      title: "",
      details: {
        0: `<strong>Bayanat for mapping and surveying services</strong> (“we,” “our,” or “us”) offers mapping, GPS
          navigation, driverless taxi service to users of [<a
            href="https://txai.taxi"
            target="_blank"
            >https://txai.taxi/</a
          >] (the “Website”) and/or users of TXAI’s mobile applications (the
          “Application”) (collectively referred to as the “Service”). This Privacy
          Policy describes the types of information we collect through the
          Service, how we use such information and to whom and under what
          circumstances we may disclose it.
          <strong>By accessing the Service on any computer, mobile phone, tablet, or
          other device (collectively, “Device”), or otherwise interacting with
          TXAI, you agree to the terms of this Privacy Policy. If you do not
          agree to the policy, please do not use the Sites.</strong>`,
        1: `We may modify this Privacy Policy at any time, and will post the current
          version on the Sites. We encourage you to periodically review our
          Privacy Policy to stay informed about how we are using the information
          we collect.`
      }
    },
    {
      title: "CONTACT US",
      details: {
        0: `If you have questions or concerns about this Privacy Policy or how we
          collect and use the information of our customers, you can contact us by
          emailing us at [<a href="mailto:support@txai.ae">support@txai.ae</a>],
          or by postal address at [2458, 24, Al Sila Tower, Abu Dhabi Global
          Market Square, Al Maryah Island, Abu Dhabi, United Arab Emirates]; ATTN:
          Privacy Request.`,
        1: `If we need, or are required, to contact you concerning any event that
          involves your information, we may do so by email, telephone, or mail.`
      }
    },
    {
      title: "User Accounts & Accessing The Services",
      details: {
        0: `Please note that when you use our Service, TXAI will set up an account
          (the “Account” or “TXAI Account”). TXAI will collect and use your
          information, including meta-data (as described below), in accordance
          with this Privacy Policy.`,
      }
    },
    {
      title: "Upon registering your Account:",
      details: {
        0: {
          firstLevel: ``,
          secondLevel: [
            `TXAI will link all of your information with your registered Account.
            This includes information received from all devices and browsers which
            you have chosen to sign in.`,
            `When using the Service, your approximate location will be visible to
            other users associated with this Account.`,
            `Your Account and information associated with your Account will be
            visible only to TXAI.`,
            `TXAI may also establish and require from time to time additional or
            different means of identification and authentication for logging in
            and accessing the Services or for accessing certain features or
            designated sections of the Services.`,
            `Your login details are your responsibility. You are fully accountable
            for any use or misuse of your Account and personal details as a result
            of conveying your login details to someone else.`
          ]
        }
      }
    },
    {
      title: "Information That We May Collect From You",
      details: {
        0: `In this Privacy Policy, the term “personal information” means
          information which you provide to us which personally identifies you,
          such as your name, email address and, in certain circumstances, your
          location and route information or other data which can be reasonably
          linked to such information by TXAI.`,
        1: `We collect personal information from you when you submit it to us,
          including by: (1) registering your Account; (2) contacting us; (3) using
          the Service or (4) you choosing to share information. This information
          may include, for example, your name, email address, location, mailing
          address, work address, telephone number, date of birth, gender, profile
          photo, your address book, usage and cookie data, and Device information.
          We may combine the information we collect from you with information we
          receive about you from other sources, such as address update services.
          Our servers will store your personal information until it is deleted in
          accordance with our retention practices which are detailed below,
          “Retention of Personal Information.”`,
        2: `In some jurisdictions, individuals may have the right to opt-in or
          withdraw consent for certain uses. If you reside in such jurisdictions,
          you may have additional rights which are detailed below, “Access,
          Correction and Deletion.”`
      }
    },
    {
      title: "Information we automatically collect",
      details: {
        0: {
          firstLevel: `We collect information about your activity in the Service, which we use
            to maintain and improve our Service. This includes things like finding
            you the preferred route and proposing favorites or destinations commonly
            traveled by you to streamline your search and travel. The activity
            information we collect may include:`,
          secondLevel: [
            `Detailed location, travel and route information. Location and route
            information is collected for example in the form of GPS signals
            (combined with a time-stamp), IP address and other information from
            sensors and receivers on or around your device, on which the
            Application is installed and activated. This location and route
            information is saved to a route history of all of the journeys you
            have made while using the Application. The types of location data we
            collect depends in part on your device and account settings. For
            example, depending on your selected device settings and/or your
            account settings we may collect information about your location and
            travel (or lack thereof) also while you are not using the app, in
            order to be able to tell you when to leave for planned drives, notify
            you about bad traffic in your area, provide you parking assistance,
            analyze how effective our ads are and more;`,
            `Terms or venues you searched;`
          ]
        }
      }
    },
    {
      title: "Meta-data (information about your device, browser and app use):",
      details: {
        0: {
          firstLevel: `TXAI collects information about the use of the Service and information
            from the device you have TXAI installed on or the browser from which you
            use our Service. For example:`,
          secondLevel: [
            `TXAI may collect and record how often you use our Service and for how
            long, your device type, unique identifiers, operating system type &
            version, battery usage, the Internet Protocol (IP) address and the
            name of the domain that serves you to access the Service, and the
            geographic location of the device that you are using to log in and use
            TXAI on.`,
            `We also collect information about the interaction of your devices with
            our Service, including, crash reports, system activity, and the date,
            time, and referrer URL of your request.`
          ]
        }
      }
    },
    {
      title: "Use of Information",
      details: {
        0: `We may use the information we collect from and about you for any of the
          following purposes: (1) to provide our Service; (2) maintain and improve
          the Service; (3) provide personalized Services. For example, TXAI may
          use your “home” and “work” and other “favorites” information (if you
          choose to provide such information) to offer routes based on how you’ve
          chosen to travel between favorite destinations in the past.; (4) to
          review Website usage and operations; (5) to address problems with the
          Website, our business or our Service; (6) to protect the security or
          integrity of the Website and our business; (7) to monitor the Website
          for compliance with our Terms of Use and the law; and (8) to contact you
          with Service updates and other informational and promotional materials
          from us and or our trusted partners, as well as from other companies.`,
        1: `If you sign up for our mailing list, we will send you informational
          emails about offers of services. At any time, you can ‘unsubscribe’
          yourself from our email list simply by clicking the ‘unsubscribe’
          button.`,
        2: `If you do not wish to receive communications from us about special
          offers and promotions, you can opt-out of receiving these communications
          by following the instructions contained in the messages you receive.
          Even if you opt-out of receiving these messages, we reserve the right to
          send you certain communications relating to the services we provide, and
          we may send you service announcements and administrative messages. We do
          not offer you the opportunity to opt-out of receiving those
          communications.`
      }
    },
    {
      title: "Disclosure of Information",
      details: {
        0: `We may disclose information collected from and about you as follows: (1)
          with your consent; (2) to our related companies and service providers,
          to perform a business, professional or technical support function for
          us; (3) to our marketing partners, advertisers or other third parties,
          who may contact you with their own offers; (4) as necessary if we
          believe that there has been a violation of the Terms of Use or of our
          rights or the rights of any third party; (5) to respond to legal process
          (such as a search warrant, subpoena or court order) and provide
          information to law enforcement agencies or in connection with an
          investigation on matters related to public safety, as permitted by law,
          or otherwise as required by law; and (6) in the event that our company
          or substantially all of its assets are acquired, your personal
          information may be one of the transferred assets. We may also disclose
          your personal information with your express consent. We may share
          aggregate, non-personally identifiable information about Service users
          with third parties. Please note that if you voluntarily submit any
          personal information for posting on the Website, such as a review or a
          blog post, the information becomes publicly available and can be
          collected and used by others, so you should use care before posting
          information about yourself online.`
      }
    },
    {
      title: "Access, Correction, & Deletion",
      details: {
        0: `We respect your right to access and correct your personal information.
          You may access your personal information by signing into your Account.
          From there, you can correct or modify your information. You may also
          exercise your rights, subject to applicable laws, to request that we
          delete or restrict access to your personal data. We may need to retain
          it for legally permitted purposes and this will be explained to you if
          necessary. If you need assistance correcting or updating your personal
          information, or would like to request that we delete your personal
          information, please contact us using the contact information detailed in
          the “Contact Us” section.`
      }
    },
    {
      title: "Retention of Personal Information",
      details: {
        0: `Personal information that we collect, access or process will be retained
          only as long as necessary for the fulfilment of the purposes for which
          it was collected, unless otherwise provided in agreements between you
          and TXAI or as required or authorized by law. Personal information that
          is no longer required to fulfill the identified purposes will be
          destroyed, erased or made de-identified.`
      }
    },
    {
      title: "Security",
      details: {
        0: `We maintain reasonable and appropriate measures designed to maintain
          information we collect in a secure manner. We have taken certain
          physical, electronic, and administrative steps to safeguard and secure
          the information we collect from visitors to the Sites. Even though we
          follow reasonable procedures to try to protect the information in our
          possession, no security system is perfect and we cannot promise, and you
          should not expect, that your information will be secure in all
          circumstances.`
      }
    },
    {
      title: "Children",
      details: {
        0: `The Service is not directed to children, nor do we knowingly collect any
          personal information from children under the age of thirteen without
          verifiable parental consent. If you believe that a child has provided
          personal information to us, please contact us promptly as described
          below, and we will endeavor to investigate and delete such information
          from our systems.`
      }
    },
    {
      title: "Third Party Websites",
      details: {
        0: `The Service may contain links to third-party websites, such as social
          media sites like Facebook and Twitter, which may have privacy policies
          that differ from our own. We are not responsible for the activities and
          practices that take place on these websites. Accordingly, we recommend
          that you review the privacy policy posted on any external site before
          disclosing any personal information. Please contact those websites
          directly if you have any questions about their privacy policies.`
      }
    },
    {
      title: "Changes to This Privacy Policy",
      details: {
        0: `We may change this privacy policy from time to time, including as
          required to keep current with rules and regulations, new technologies
          and security standards. When we do, we will post the change(s) on our
          Website. If we change the policy in a material and retroactive manner,
          we will provide appropriate notice to you.`
      }
    },
    {
      title: "Your California Privacy Rights",
      details: {
        0: `If you are a residident of the state of California, U.S.A., California
          law permits our customers who are California residents to request
          certain information about our disclosure of personal information to
          third parties for their own direct marketing purposes during the
          preceding calendar year. This request is free and may be made once a
          year. To make such a request, please write to us at the following
          address: [TXAI Project,Floor 3, Wafra Square Office Building, Najmat Abu
          Dhabi-Abu Dhabi] ATTN: Privacy Request. Right to Removal of Posted
          Information—California Minors`,
        1: `If you are under 18 years of age, reside in California, U.S.A. and have
          a registered account with us, you have the right to request removal of
          unwanted information that you publicly post on the Service. To request
          removal of such information, you can contact us as provided below. Upon
          receiving such a request, we will make sure that the information is not
          publicly available on the Service, but the information may not be
          completely or comprehensively removed from our systems and databases.`
      }
    },
    {
      title: "International Transfers",
      details: {
        0: `TXAI operates globally, which means personal data may be stored and
          processed (for example stored in a data center) in any country where we
          or our service providers have facilities or hold events. By using TXAI
          or providing personal data for any of the purposes stated above, you
          acknowledge that your personal data may be transferred to or stored in
          Abu Dhabi or in other countries around the world. Such countries may
          have data protection rules that are different and less protective than
          those of your country. Your information may be available to government
          authorities under lawful orders and law applicable in such
          jurisdictions. By using the Service and/or providing personal
          information to us, you consent to transfer of your information to our
          facilities as described in this Privacy Policy.`,
        1: {
          firstLevel: `If you are a resident of the European Economic Area (EEA), and your
            personal data is transferred outside of the EEA, we will:`,
          secondLevel: [
            `Process it in a territory which the European Commission has determined
            provides an adequate level of protection for personal information; or`,
            `Implement appropriate safeguards to protect your personal information,
            including transferring it in accordance with applicable transfer
            mechanism, including the European Commission’s standard contractual
            clauses.`
          ]
        }
      }
    },
    {
      title: "European Privacy Rights",
      details: {
        0: {
          firstLevel: `If you reside in the EEA, you may have the right to exercise certain
            privacy rights available to you under applicable laws. We will process
            your request in accordance with applicable data protection laws. We may
            need to retain certain information for record-keeping purposes or to
            complete transactions that you began prior to requesting any deletion.`,
          secondLevel: [
            `Right not to provide consent or to withdraw consent. We may seek to
            rely on your consent in order to process certain personal data. Where
            we do so, you have the right not to provide your consent or to
            withdraw your consent at any time. This does not affect the lawfulness
            of the processing based on consent before its withdrawal.`,
            `Right of access and/or portability. You may have the right to access
            the personal data that we hold about you and, in some limited
            circumstances, have that data provided to you so that you can provide
            or “port” that data to another provider.`,
            `Right of erasure. In certain circumstances, you may have the right to
            the erasure of personal data that we hold about you (for example if it
            is no longer necessary for the purposes for which it was originally
            collected).`,
            `Right to object to processing. You may have the right to request that
            TXAI stop processing your personal data and/or to stop sending you
            marketing communications.`,
            `Right to rectification. You may have the right to require us to
            correct any inaccurate or incomplete personal information.`,
            `Right to restrict processing. You may have the right to request that
            we restrict processing of your personal data in certain circumstances
            (for example, where you believe that the personal data we hold about
            you is not accurate or lawfully held).`,
            `Right to lodge a complaint to your local Data Protection Authority. If
            you are an EEA resident, you have the right to complain to a data
            protection authority about our collection and use of your personal
            data.`
          ]
        }
      }
    },
    {
      title: "Right to Lodge Complaints",
      details: {
        0: `We are transparent about the ways in which we collect and use personal
          information, and welcome your questions and concerns. If you have any
          concern or complaint about the way we handle your personal information,
          please contact us as described above. To the extent you believe we have
          not addressed your concerns or otherwise choose to do so, you have the
          right to lodge a complaint with a supervisory authority in the country
          where you reside.`
      }
    },
  ]
}