export default {
  menuList: [
    {
      name: "Home",
      route: "/",
    },
    {
      name: "Downloads",
      route: "/download",
    },
    {
      name: "Newsroom",
      route: "/news",
    },
    {
      name: "About",
      route: "/about",
    },
    {
      name: "Help",
      route: "/help",
    },
  ],
  download: {
    title: "Download the TXAI Mobile App"
  },
  readMore: "Read More",
  need_help: {
    title: "Still need help?",
    contact: "Contact us"
  },
  connect_channel: {
    title: "Connect with us on our social channels"
  },
  footer: {
    copyright: "Copyright © 2022 The Project by Bayanat. All Rights Reserved",
    list: [
      {
        title: "Our products",
        link: [
          {
            path: "/download",
            name: "TXAI Mobile App"
          }
        ]
      },
      {
        title: "Help and support",
        link: [
          {
            path: "/help",
            name: "FAQ"
          },
          {
            path: "/contact",
            name: "Contact us"
          }
        ]
      },
      {
        title: "About",
        link: [
          {
            path: "/about",
            name: "About TXAI"
          },
          {
            path: "/news",
            name: "Newsroom"
          },
          {
            path: "/legal",
            name: "User agreement"
          },
          {
            path: "/security",
            name: "Privacy policy"
          }
        ]
      },
    ]
  }
}