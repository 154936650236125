export default {
  name: "اتفاقية المستخدم",
  list: [
    {
      title: "1. ملاحظة خاصة",
      details: {
        0: `1.1 "بيانات لخدمات الخرائط والمساحة - بيانات - ش.م.ع.
          شركة تأسست بموجب قوانين دولة الإمارات العربية المتحدة بموجب
          رقم التسجيل CN-1139765 ("<strong> بيانات </strong>"
          "<strong> شركة </strong>" "<strong> نحن </strong>" ، "<strong> نحن </strong>"
          أو "<strong> لدينا </strong>").`,
        1: `"1.2 تحكم هذه الشروط استخدامك لمنصة TAXI (ملف
          "<strong> النظام الأساسي </strong>") وتطبيق الإنترنت عبر الجوال
          ("<strong> التطبيق </strong>") الذي يمكنك من خلاله تحديد موعد
          خدمات السفر بالقيادة الذاتية ("<strong> الخدمة </strong>") و
          إنشاء عقد ملزم بينك وبيننا ".`,
        2: `3.1 يرجى قراءة هذه الشروط بدقة لكي تفهم التزاماتك وحقوقك فيما يتعلق بخدماتنا بشكل صحيح، لا سيما الأحكام التي تنطوي على مصالح كبرى والمهلة الزمنية وطريقة أداء الالتزامات مثل احتياطات السلامة وتحذيرات المخاطر والمسؤوليات المدنية (مثل أحكام الإعفاء التي تعفي أو تحد من مسؤوليات الشركة، والأحكام التي تقيد حقوقك، والأحكام المتعلقة بالإخلال بالعقد)، والولاية القضائية، وحل النزاعات، وما إلى ذلك. يمكن تظليل هذه الأحكام بالخط العريض. ويرجى قراءتها بعناية.`,
        3: `4.1 يجب عليك أيضًا الالتزام بالإشعارات القانونية وسياسات حماية المعلومات الشخصية ومعايير الرسوم وقواعد الخدمة للمنصة واللوائح والإشعارات والاتفاقيات الأخرى (ويشار إليها مجتمعة "بالإشعارات الأخرى") التي تصدرها وتعدلها المنصة من وقت لآخر. يمكنك الاطلاع على الإشعارات الأخرى من خلال المنصة. ويجب أن توافق على قبول هذه الشروط قبل استخدام الخدمة.`,
        4: `5.1 بموجب تنزيل وتثبيت والدخول على الخدمات و/أو المنصة أو بخلاف ذلك استخدامها أو أي من خصائصها، فإنك توافق على الامتثال والالتزام بهذه الشروط والإشعارات الأخرى، وتشكل هذه الشروط والإشعارات الأخرى المستندات القانونية الملزمة لك قانوناً. إذا قمت بالإخلال أو انتهاك أي من الشروط، سيكون من حقنا حظر وصولك إلى التطبيق والمنصة على الفور. إذا كنت لا توافق على قبول هذه الشروط أو أي من أحكامها أو أي محتوى للإشعارات الأخرى، يجب عليك إيقاف عملية التسجيل في الخدمة على الفور.`,
        5: `1.6 أنت توافق صراحةً على أن للمنصة الحق في تعديل ونشر هذه الشروط من وقت لآخر وفقًا للتغييرات في القوانين واللوائح و/أو المتطلبات التشغيلية للشركة. وتصبح أي تعديلات لهذه الاتفاقية سارية المفعول خلال خمسة عشر (15) يوماً من تاريخ النشر. سوف نقوم بإعلامك بمعظم التغييرات المذكورة عن طريق الإعلان المسبق أو الإشعارات الفورية أو من خلال النافذة المنبثقة. يمكنك كذلك الاطلاع على أحدث إصدار للاتفاقية المعدلة من على المنصة في أي وقت. على أية حال، قد نجري بعض أنواع التغيير دون إخبارك مسبقًا - وهو ما قد يحدث حيث يُطلب منا إجراء تغيير بسبب المتطلبات القانونية و/أو التنظيمية وليس هناك متسع من الوقت لإعلامك بهذه التغييرات.`,
        6: `7.1 إذا كنت لا توافق على المحتوى المعدل، يجب عليك التوقف على الفور عن استخدام المنصة والخدمة. وفي حال الاستمرار في استخدام التطبيق أو المنصة أو الخدمة، سيتم اعتبارك بأنك موافقًا على تعديل الشركة لهذه الشروط.`,
        7: `8.1 لتكون مؤهلاً لاستخدام الخدمة، يجب ألا يقل عمرك عن 18 أو أكثر وفقاً لسن الرشد في الولاية القضائية التي أنت تابع لها. لاستيفاء المتطلبات المذكورة أعلاه، يجب عليك تزويدنا بهويتك الإماراتية وأي وثائق أخرى نطلبها.`
      }
    },
    {
      title: "2. محتوى الخدمة",
      details: {
        0: `1.2 باستطاعتك طلب واستخدام السيارة ذاتية القيادة من خلال المنصة. تشير عبارة "خدمة القيادة الذاتية" التي توفرها المنصة إلى حقيقة أن معلومات سرعة الأجسام المتحركة يتم جمعها بواسطة برنامج حاسوب ذكي وعدد كبير من أجهزة الاستشعار (بما في ذلك أجهزة استشعار التي تغطي المركبة بالكامل مع مجال رؤية بزاوية 360 درجة و ليدار (Lidar) و جي بي اس (GPS)  والليزر والكاميرات) المثبتة على السيارة ذاتية القيادة، ويستخدم نظام الملاحة بالقصور الذاتي المثبت علي الكمبيوتر خوارزمية تقدير الوضع لحساب الإحداثيات باستمرار واَلسُّمُوت واتجاه وسرعة السيارة والأشياء المحيطة بها، بهدف تقديم خدمة القيادة الذاتية والقيادة الآمنة للسيارة.
          <br />
          عندما نتلقى حجزك، سنقوم بإرسال سيارة وفقًا للمنصة. وبعد نجاح إرسال المركبة، سوف نقوم بتخطيط مساراً لرحلتك وفقًا لموقعك المحدد ونقوم بتوصيلك إلى وجهتك.`,
        1: `2.2. يجب أن توافق وتؤكد على أننا نقدم فقط الخدمات المنصوص عليها في هذه الشروط، وأنك تتحمل مسؤولية توفير المعدات المتعلقة بالخدمة (مثل أجهزة الكمبيوتر الشخصية والهواتف المحمولة والأجهزة الأخرى المتعلقة بالدخول على الإنترنت أو شبكات الهواتف الجوالة).`,
        2: `2.3 أنت توافق وتؤكد بأن هذه الشروط تنطبق أيضًا على الخدمات الأخرى التي نقدمها نحن أو الشركات المنتسبة لنا من خلال المنصة.`,
        3: `2.4 يتم توفير أي خدمات أخرى يقدمها طرف ثالث من خلال المنصة أو عبر رابط على المنصة بشكل مُستقل من قبل الطرف الثالث، ويتحمل الطرف الثالث المسؤولية وفقًا لذلك، ولا تقدم الشركة أي ضمان لذلك.`
      }
    },
    {
      title: "3. قواعد الاستخدام",
      details: {
        0: `1.3  بعد عملية تسجيل المستخدم بنجاح، ستمنح الشركة لكل مستخدم حساب مستخدم تملكه الشركة، وسيحصل المستخدم على الحق في استخدام الحساب بعد إكمال إجراءات التسجيل. يعود الحق في استخدام الحساب إلى مقدم الطلب الأولي فقط، ويحظر أي شكل من أشكال التنازل، بما في ذلك وعلى سبيل المثال لا الحصر، التخلي أو الاقتراض أو التأجير أو نقل الملكية أو البيع. يتحمل المستخدم المسؤولية عن المحافظة على الحساب وكلمة المرور، وهو مسؤول مسؤولية كاملة عن جميع الأنشطة التي تتم بموجب الحساب وكلمة المرور (بغض النظر عما إذا تم إجراؤهما من قبل المستخدم أو صرح المستخدم بالقيام بها). في الوقت نفسه، ووفقًا لاتفاقيات المنصة ذات الصلة، فإنك تفوضنا في مشاركة معلومات حساب المستخدم مع الشركات المنتسبة لنا ومنصات شركة بيانات الأخرى. أنت لا تحتاج إلى تسجيل حساب آخر للاستفادة من النطاق الكامل للخدمات التي تقدمها المنصة.`,
        1: `2.3 لا يجوز للمستخدم نقل ملكية أو إعارة حسابه وكلمة المرور للآخرين لاستخدامها. إذا اكتشف المستخدم بأن حسابه قد تم استخدامه بشكل غير قانوني من قبل آخرين، فعليه إخطار الشركة على الفور. يتحمل المستخدم نفسه جميع الخسائر المتكبدة نتيجة استخدام الأخرين غير القانوني للحساب وكلمة المرور بسبب القرصنة أو إهمال المستخدم في المحافظة على الحساب وكلمة المرور، ولن تتحمل الشركة أي مسؤولية. وإذا تسبب هذا في خسائر للشركة، يوافق المستخدم على تعويض خسائر الشركة. وفي حال إخفاقك في المحافظة على حسابك وكلمة مرورك بشكل صحيح، أو انتهكت هذا الشرط، أو قمت بالسماح لمن ليس لديهم الأهلية الكاملة لممارسة الأعمال المدنية باستخدام حسابك للركوب بمفردهم، فأنت تتحمل الالتزامات والمسؤوليات الناشئة عن ذلك. وإذا تسبب ذلك في خسائر للآخرين، فسوف تتحمل أنت المسؤولية المقابلة.`,
        2: `2.3 لا يجوز للمستخدم نقل ملكية أو إعارة حسابه وكلمة المرور للآخرين لاستخدامها. إذا اكتشف المستخدم بأن حسابه قد تم استخدامه بشكل غير قانوني من قبل آخرين، فعليه إخطار الشركة على الفور. يتحمل المستخدم نفسه جميع الخسائر المتكبدة نتيجة استخدام الأخرين غير القانوني للحساب وكلمة المرور بسبب القرصنة أو إهمال المستخدم في المحافظة على الحساب وكلمة المرور، ولن تتحمل الشركة أي مسؤولية. وإذا تسبب هذا في خسائر للشركة، يوافق المستخدم على تعويض خسائر الشركة. وفي حال إخفاقك في المحافظة على حسابك وكلمة مرورك بشكل صحيح، أو انتهكت هذا الشرط، أو قمت بالسماح لمن ليس لديهم الأهلية الكاملة لممارسة الأعمال المدنية باستخدام حسابك للركوب بمفردهم، فأنت تتحمل الالتزامات والمسؤوليات الناشئة عن ذلك. وإذا تسبب ذلك في خسائر للآخرين، فسوف تتحمل أنت المسؤولية المقابلة.`,
      }
    },
    {
      title: "4. الاستخدام المقبول",
      details: {
        0: {
          firstLevel: `1.4 عند استخدام الخدمات و/أو المنصة و/أو التطبيق، يجب عليك دائمًا القيام بذلك لأغراض قانونية ومصرح بها ومقبولة. لن تقوم أنت أو تسمح لأي شخص يستخدم حسابك و/أو يدخل إلى المنصة بالقيام بما يلي:`,
          secondLevel: [
            `أ. استخدام الخدمات و/أو الموقع الإلكتروني بأي طريقة غير قانونية لأي غرض غير قانوني أو بأي طريقة تتعارض مع هذه الشروط أو تنتهكها؛`,
            `ب. تخل أو تنتهك حقوق شركة بيانات أو أي من شركات مجموعتها أو مستخدمينا أو أي طرف ثالث، بما في ذلك الحقوق المتعلقة بالخصوصية أو الملكية الفكرية أو حقوق الملكية الأخرى؛`,
            `ج. تقديم معلومات كاذبة أو غير صحيحة أو مضللة؛`,
            `د. الانخراط في أي أنشطة و/أو معاملات احتيالية أو مشتبه بها محتملة، بما في ذلك تلقي الأموال التي قد تكون من مصادر احتيالية أو إضافة أموال إلى حسابك والتي قد تكون مرتبطة بأنشطة إجرامية؛`,
            `ه. رفض التعاون في تحقيق أو تقديم أي معلومات نطلبها، بما في ذلك ما يتعلق بالتحقق من هويتك؛`,
            `و. إرسال أي رسالة أو مادة أو محتوى آخر غير قانوني أو فاحش أو تشهيري أو تهديدي أو تخويفي أو مضايقة أو كراهية أو عنصرية أو عرقية، أو يمكن أن يحرض أو يشجع على سلوك غير قانوني أو بخلاف ذلك غير مناسب، بما في ذلك الترويج لجرائم العنف؛`,
            `ح. السعي لإلحاق الضرر بشركة بيانات أو شركات مجموعتنا أو أي من مستخدمينا أو عملائنا؛ أو`,
            `1. الدخول أو استخدام أو نسخ أو تعديل أو توزيع أو عرض أو استغلال أو إعداد أعمال مشتقة اعتماداً على خدماتنا بطريقة غير مسموح أو غير مصرح بها، أو بطرق تضر بنا أو بشركات مجموعتنا أو خدماتنا أو أنظمتنا أو مستخدمينا أو غيرهم. وعلى وجه الخصوص، يجب عليك عدم القيام، أو السماح لأي شخص بأن يقوم، باستخدام حسابك أو الوصول إلى الموقع الإلكتروني للقيام بما يلي:`,
            `1. عكس هندسة أو تغيير أو تعديل أو إنشاء أعمال مُشتقة من خدماتنا أو تفكيكها أو استخراج رموز منها باستثناء إل الحد الذي يسمح به القانون؛`,
            `2. إرسال أو تخزين أو نقل فيروسات أو أكواد كمبيوتر ضارة أخرى من خلال خدماتنا أو إليها؛`,
            `3. الدخول أو محاولة الدخول غير المصرح به إلى أنظمتنا أو تلك الخاصة بشركات مجموعتنا؛`,
            `4. إنشاء حسابات لخدماتنا من خلال وسائل غير مصرح بها؛`,
            `5. جمع المعلومات الخاصة بمستخدمينا أو عنهم بأي طريقة غير مسموح بها أو غير مصرح بها؛ و / أو`,
            `6. بيع خدماتنا أو إعادة بيعها أو تأجيرها أو فرض رسوم مقابل استخدامها.`
          ]
        }
      }
    },
    {
      title: "5. تغيير أو تعليق أو إنهاء الخدمة",
      details: {
        0: `1.5 أنت تفهم بأن الشركة بحاجة إلى فحص أو صيانة منصات تقديم الخدمة (مثل مواقع الإنترنت وشبكات الهواتف المتحركة) أو المعدات ذات الصلة على أساس مُنتظم أو غير مُنتظم، وأن اتصال الإنترنت مقيد بأوجه عدم اليقين الحالية مثل استقرار الشبكة العالمية، والوضع التقني، وموقع المستخدم والشبكة المستخدمة، وإمداد الكهرباء، والرقابة الحكومية الحكومي، وفيروسات الكمبيوتر، وهجمات القرصنة، وما إلى ذلك. في حال توقفت الخدمة نتيجة لأي من هذه الظروف، فلن تتحمل الشركة أي مسؤولية عن ذلك، ولا يجب عليها تقديم إشعار مُسبق إلا في ظروف خاصة.`,
        1: {
          firstLevel: `2.5 في حالة وقوع أي من الظروف التالية، يكون من حق الشركة تعليق أو إنهاء تقديم كل أو جزء من الخدمات بموجب هذه الشروط لك في أي وقت دون أي مسؤولية تجاهك أو تجاه أي طرف ثالث في الحالات التالية:`,
          secondLevel: [
            `أ. المعلومات الشخصية التي قدمتها غير صحيحة؛`,
            `ب. إذا كنت تنتهك قواعد الاستخدام المنصوص عليها في هذه الشروط؛`,
            `ج. القيام بسلوكياتك تنتهك القوانين أو اللوائح أو الأنظمة الحكومية.`
          ]
        },
        2: `3.5 يجب ألا ينتهك حساب المستخدم المسجل أو سلوك المستخدم وطريقة استخدام المنصة القوانين أو الانظمة أو متطلبات السياسة الوطنية، أو ينتهك الحقوق والمصالح القانونية لأي طرف ثالث. في حالة وقوع أي من الظروف المذكورة، يتحمل المستخدم المسؤولية القانونية المقابلة؛ في غضون ذلك، يحق للشركة منع المستخدم من الاستمرار في استخدام الحساب أو منع المستخدم من الاستمرار في استخدام المنصة، أو أن تقوم بتقديم المعلومات ذات الصلة للسلطات الحكومية أو المحاكم أو النيابات وفقًا للقوانين ذات الصلة أو الأوامر الحكومية. إذا تسبب الظرف المذكور في وقوع خسارة للشركة أو طرف ثالث، يكون المستخدم مسؤولاً عن تعويض هذه الخسارة.`
      }
    },
    {
      title: "* 6. التراخيص والملكية الفكرية *",
      details: {
        0: `1.6 سوف نحتفظ، طوال علاقتنا معك، بملكية و/أو السيطرة، حسب الاقتضاء، على الخدمات والتطبيق والمنصة وكافة حقوق الملكية الفكرية الواردة فيها، بما في ذلك جميع حقوق النشر والعلامات التجارية والنطاقات والشعارات والأسرار التجارية وبراءات الاختراع وحقوق الملكية الفكرية الأخرى التي كانت موجودة أو الموجودة حالياً أو التي قد تكون موجودة في المستقبل ("الملكية الفكرية"). لا يجوز لك استخدام حقوق الملكية الفكرية الخاصة بنا ما لم يُسمح بذلك بموجب هذه الشروط أو بموجب موافقتنا الخطية الصريحة (والتي يمكنك طلبها عن طريق الاتصال بنا باستخدام بيانات الاتصال الموضحة في هذه الشروط).`,
        1: `2.6 لا يجوز لك، دون موافقة خطية من الشركة، تنفيذ أو استخدام أو نقل أو الترخيص لأي طرف ثالث لتنفيذ أو استخدام أو نقل حقوق الملكية الفكرية المذكورة لأي غرض ربحي أو غير ربحي، وتحتفظ الشركة بالحق في التحقيق في الأعمال غير المصرح بها المذكورة.`,
        2: `3.6 لكي نتمكن من تقديم خدماتنا و/أو تطبيقنا لك على أفضل وجه ممكن، فإنك تمنح شركة بيانات حقًا عالميًا مجانيًا وقابل للترخيص من الباطن وقابل للتحويل لاستخدام وإعادة إنتاج وتوزيع وإنشاء أعمال مشتقة من وعرض المعلومات (بما في ذلك أي محتوى) التي تقوم بتحميلها أو تخزينها أو إرسالها أو تلقيها على أو من خلال استخدام الخدمات و/أو الموقع الإلكتروني. سوف نستخدم هذه الحقوق فقط لغرض حصري وهو تقديم خدماتنا.`
      }
    },
    {
      title: "7. التفويض والرخصة",
      details: {
        0: `1.7 شريطة التزام المستخدم بهذه الشروط، فإننا نمنح المستخدم ترخيصًا قابلاً للإلغاء ومحدودًا وغير حصري وغير قابل للترخيص من الباطن وغير قابل للتحويل على النحو التالي: تنزيل وتثبيت نسخة من التطبيق على جهاز نقال واحد يمتلكه المستخدم أو يتحكم فيه، وتشغيل هذه النسخة من التطبيق فقط للاستخدام الشخصي للمستخدم.`,
        1: {
          firstLevel: `2.7 لا يجوز للمستخدم القيام بما يلي:`,
          secondLevel: [
            `أ. ترخيص أو الترخيص من الباطن أو بيع أو إعادة بيع أو نقل ملكية أو التنازل أو توزيع الخدمة أو التطبيق بأي شكل من الأشكال، أو إجراء التطوير التجاري أو تقديمه إلى أي طرف ثالث؛`,
            `ب. تحرير أو تعديل أو تجميع أو إجراء هندسة عكسية للخدمة أو التطبيق أو إنشاء منتجات مشتقة وفقًا لذلك؛`,
            `ج. إنشاء "رابط" على الإنترنت للخدمة، أو "مرآة" أو "تصميم" أي تطبيق على أي خادم أو جهاز لاسلكي أو قائم على الإنترنت آخر؛`,
            `د. الهندسة العكسية أو الوصول إلى التطبيق أو تصميم أو إنشاء منتجات أو خدمات منافسة، أو استخدام أفكار أو رسومات مشابهة للخدمة أو التطبيق لتصميم أو إنشاء منتجات، أو نسخ أي أفكار أو خصائص أو وظائف أو رسومات للخدمة أو التطبيق؛`,
            `ه. بدء البرامج أو النصوص التلقائية، أو إرسال طلبات خادم متعددة في الثانية، أو زيادة العبء على الخدمة أو التطبيق بشكل مفرط أو إعاقة عملها و/أو أدائها؛ أو محاولة الوصول إلى المواقع الإلكترونية أو التطبيقات أو الخدمات أو الأنظمة أو الشبكات ذات الصلة دون تصريح؛`,
            `و. عرقلة أو تعطيل سلامة أو أداء المواقع الإلكترونية أو التطبيقات أو الخدمات أو البيانات المتضمنة فيها؛`,
            `ز. إرسال أو تخزين المواد التي تحتوي على فيروسات البرامج أو أحصنة طروادة أو الديدان أو غيرها من أكواد الكمبيوتر أو النصوص أو الملفات أو العملاء أو البرامج الضارة؛`,
            `ح. إرسال أو تخزين المواد المخالفة أو الفاحشة أو التهديدية أو التشهيرية أو غيرها من المواد غير القانونية، بما في ذلك المواد التي تضر بالأطفال أو تنتهك حقوق الخصوصية لأطراف ثالثة.`
          ]
        },
        2: `3.7 يحق لنا، إلى أقصى حد تسمح به القوانين والأنظمة، التحقيق والمراجعة ورفع دعوى قضائية بشأن أي من الانتهاكات المذكورة للقوانين والأنظمة. كما يمكننا المشاركة ومساعدة وكالات إنفاذ القانون في مقاضاة المستخدمين الذين ينتهكون هذه الشروط. إذا كنا نعتقد أن أي محتوى ينتهك هذه الشروط أو يضر بخلاف ذلك بالموقع الإلكتروني و/أو المنصة و/أو الخدمات أو التطبيقات الموجودة فيه، فإننا نحتفظ بالحق في حذف أو تعطيل الوصول إلى هذا المحتوى في أي وقت دون إشعار مسبق.`
      }
    },
    {
      title: "8. التعهدات والضمانات",
      details: {
        0: {
          firstLevel: `1.8 تتعهد وتضمن الشركة ما يلي:`,
          secondLevel: [
            `أ. تأسست الشركة وفقًا لقوانين دولة الإمارات العربية المتحدة ولديها مؤهلات تجارية قانونية؛`,
            `ب. توفر الشركة تأمين إلزامي وتجاري على السيارات ذاتية القيادة، كما تؤمن ضد المسؤولية للعاملين في السيارة.`
          ]
        },
        1: {
          firstLevel: `2.8 يَعد ويقر ويضمن المستخدم ما يلي:`,
          secondLevel: [
            `أ. أنه يتمتع بأهلية قانونية مُستقلة كافية لقبول خدمة السفر بالسيارة ذاتية القيادة التي تقدمها الشركة؛`,
            `ب. أن الشركة تمتلك حقوق الملكية الفكرية في الآراء والاقتراحات والأفكار والخطط إلى أخره الخاصة بالاختبارات (يشار إليها مجتمعة باسم "ملاحظات الاختبار") التي قدمها مستخدمو السيارة ذاتية القيادة، ويتنازل المستخدم عن أي حقوق في ملاحظات الاختبار، ولن يقوم بنقل ملكية أو ترخيص حقوق الملكية الفكرية الخاصة بملاحظات الاختبار إلى أي طرف ثالث؛`,
            `ج. يجب على الشركة أن تقوم، على صلة باستخدام الخدمات و/أو المنصة و/أو التطبيق، بجمع بياناتك الشخصية وتخزينها واستخدامها ومشاركتها وبخلاف ذلك معالجتها.`,
            `د. في حالة حدوث إصابة شخصية أو خسارة أو ضرر في الممتلكات تتكبدها الشركة وموظفيها ومديريها ومساهميها ومستشاريها وكذلك السائقين الخارجيين الذين يتم إرسالهم للشركة أثناء استخدام الخدمة تتعلق بأسباب تعزى للمستخدم، ويتحمل المستخدم جميع المسؤوليات والأضرار ذات الصلة بما في ذلك أتعاب المحاماة. وفي حال قامت الشركة بدفع تعويضًا في أول الأمر، تحتفظ الشركة بالحق في استرداد ذلك التعويض من المستخدم.`
          ]
        }
      }
    },
    {
      title: "9. حدود المسؤولية",
      details: {
        0: `1.9 لا تزال تقنية القيادة الذاتية في مرحلة التطوير التقني. إننا نقدم خدمات على أساس المستوى التقني الحالي والوضع القياسي بما يتماشى مع مرحلة تطوير الصناعة، وحماية صحة وسلامة المستخدمين الشخصية وسلامة الممتلكات. على أية حال، قد يظل هناك عدم نضج وعدم اكتمال وعدم استقرار وعيوب أخرى. قد تواجه السيارات ذاتية القيادة حاليًا مخاطر تقنية كامنة، ووفقًا للمستوى التقني الحالي، لا يمكن العثور وتحديد العيوب، والتي تشمل، بما في ذلك وعلى سبيل المثال لا الحصر، أخطاء برنامج الكمبيوتر وأخطاء أجهزة الاستشعار وأخطاء معالجة الكمبيوتر وأخطاء تحديد المواقع عبر نظام تحديد المواقع العالمي(GPS) ، وقد تؤدي هذه المخاطر الإضافية إلى زيادة مخاطر الحوادث التي تسبب الحوادث أو الوفاة بشكل كبير.`,
        1: `2.9 لن تكون الشركة مسؤولة عن الأضرار غير المباشرة أو العرضية أو الخاصة أو الزجرية أو العقابية أو التبعية، بما في ذلك خسارة الأرباح أو فقد البيانات أو الإصابة الشخصية أو تلف الممتلكات المرتبط أو الذي يكون على صلة أو الناتج عن أي استخدام الخدمات، بغض النظر عن نوع الإهمال من جانب الشركة (سواء الفاعل أو الإيجابي أو الوحيد أو المتزامن)، حتى لو تم إخطار الشركة بإمكانية حدوث مثل هذه الأضرار.`,
        2: `3.9 لن تكون الشركة مسؤولة عن أي أضرار أو مسؤولية أو خسائر ناجمة عن: (1) استخدامك أو اعتمادك على الخدمات أو عدم قدرتك على الدخول إلى الخدمات أو استخدامها؛ أو (2) أي معاملة أو علاقة بينك وبين أي مزود طرف ثالث، حتى لو تم إخطار الشركة بإمكانية حدوث مثل هذه الأضرار. كما لن تكون الشركة مسؤولة عن التأخير أو الإخفاق في الأداء الناتج عن أسباب خارجة عن سيطرة الشركة المعقولة.`,
        3: `4.9 لن تكون الشركة مسؤولة عن أي أضرار أو مسؤولية أو خسائر ناجمة عن عدم وجود أو التركيب أو الاستخدام غير الصحيح لأنظمة تثبيت أطفال الضيوف في الرحلات المطلوبة من خلال الخدمات والتي تتطلب قانونا نظام لتثبيت الأطفال.`,
        4: `5.9 لا تزعم حدود وإخلاء المسؤولية في هذا القسم إلى تقييد المسؤولية أو تغيير حقوقك كمستهلك والتي لا يمكن استثنائها بموجب القانون المعمول به. تكون مسؤولية الشركة محدودة إلى الحد الذي يسمح به القانون. لن يكون لهذا الشرط أي تأثير على شرط اختيار الشركة للقانون المختار المنصوص عليه أدناه.`,
        5: `6.9 لا تقدم الشركة أي نوع من أنواع الضمانات للخدمة، بما في ذلك على سبيل المثال لا الحصر، حسن توقيت الخدمة وسلامتها ودقتها، ولا تتحمل الشركة أي مسؤولية عن الأضرار المباشرة وغير المباشرة والعرضية والخاصة واللاحقة والمخاطر الناشئة عن استخدام أو عدم القدرة على استخدام هذه الخدمة تحت أي ظرف من الظروف.`
      }
    },
    {
      title: "10. أحكام عامة",
      details: {
        0: {
          firstLevel: `بالإضافة إلى أي شروط وأحكام أخرى ضمن هذه الشروط، يرجى العلم بما يلي:`,
          secondLevel: [
            `<strong>الاتفاقية الكاملة:</strong>
            تشكل هذه الشروط الاتفاقية الكاملة المبرمة بينك وبين شركة بيانات فيما يتعلق باستخدامك للخدمات و/أو الموقع الإلكتروني.`,
            `<strong>مخالفات القانون المحلي:</strong>
            لا ينبغي الدخول إلى خدماتنا ومنصتنا أو بخلاف ذلك استخدامها في أي ولاية قضائية حيث تُحظر أي أو كافة الخدمات أو المنصة، حسب الاقتضاء، إلى درجة محددة.`,
            `<strong>التخلي:</strong>
            لا يُسمح بأي تخلي عن أي شروط أو التزامات أو حقوق بموجب هذه الاتفاقية دون موافقتنا الخطية.`,
            `<strong>التنازل:</strong>
            يجوز لنا التنازل عن أو نقل كل أو بعض حقوقنا والتزاماتنا بموجب هذه الشروط إلى طرف آخر. لا يجوز لك التنازل عن أو بخلاف ذلك نقل أي من حقوقك والتزاماتك بموجب هذه الشروط إلى طرف آخر.`,
            `<strong>الأطراف الثالثة:</strong>
            باستثناء ما هو مذكور أو متوخى في هذه الشروط، لا تمنح هذه الشروط أي طرف ثالث أي حقوق.`,
            `<strong>قابلية الفصل:</strong>
            إذا تم اعتبار أي شرط من هذه الشروط بأنه غير قانوني أو غير مشروع أو باطل أو غير قابل للتنفيذ لأي سبب من الأسباب، يُعتبر هذا الشرط قابلاً للفصل عن شروطنا ولن يؤثر على صلاحية وقابلية إنفاذ الشروط المتبقية.`,
            `<strong>الإشعارات:</strong>
            يجب إرسال أي إشعار يتطلب منك تقديمه إلينا بموجب هذه الشروط إلى: <a href="mailto:services@txai.taxi">services@txai.taxi</a> .
            يتم إرسال أي إشعار مطلوب منا تقديمه إليك عبر التطبيق و/أو عبر إعلانات الشبكة الإلكترونية أو الرسائل النصية للهواتف النقالة أو البريد الإلكتروني؛ وتعتبر هذه الإشعارات قد تم تسليمها إلى المستلم في تاريخ التسليم.`,
            `<strong>معلومات الاتصال:</strong>
            أنت مسؤول عن إبلاغنا إذا تغيرت معلومات الاتصال أو الهوية الخاصة بك أو تأكيد هذه المعلومات لنا بناءً على طلبنا.
            <br />اللغة: تم إعداد هذه الشروط باللغة الإنكليزية لذا فاللغة الافتراضية هي الإنكليزية.`,
            `الشكاوى إذا لم تكن راضيًا عن الخدمات، يرجى الاتصال بنا من خلال البريد الإلكتروني التالي: <a href="mailto:services@txai.taxi">services@txai.taxi</a>`
          ]
        },
      }
    },
    {
      title: "11. حل المنازعات والقانون الحاكم",
      details: {
        0: `إننا نحاول تقديم قواعد واضحة حتى نتمكن من الحد من الخلافات بينك وبيننا ونأمل في تجنبها بالكامل. على أية حال، في حالة نشوء نزاع، يرجى الاطلاع على ما يلي لمعرفة كيفية حله والقوانين التي سيتم تطبيقها.`,
        1: `ما لم يتطلب القانون خلاف ذلك صراحةً، فإن هذه الشروط وأي نزاع أو دعوى (بما في ذلك النزاعات أو الدعاوى غير التعاقدية) تنشأ عن أو فيما يتعلق بها أو موضوعها أو بنائها يخضع ويفسر وفقًا لقوانين إنجلترا وويلز.`,
        2: `أنت ونحن نوافق على أن يكون لمحاكم سوق أبوظبي العالمي في أبوظبي الاختصاص القضائي الحصري في تسوية أي نزاع أو دعوى (بما في ذلك النزاعات أو الدعاوى غير التعاقدية) الناشئة عن أو في فيما يتعلق بهذه الشروط أو موضوعها أو بنائها.`
      }
    },
  ]
}